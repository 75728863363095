<template>
  <div>
    <base-header class="pb-6"></base-header>
    <b-container fluid class="mt--6 h-100">
      <b-row class="row d-flex">
        <b-col sm="6">
          <card no-body header-classes="py-3">
            <b-row
              align-v="center"
              slot="header"
              class="d-flex justify-content-between"
            >
              <b-col>
                <h2 class="mb-0">
                  Upcoming Sessions
                  <div
                    class="icon-md icon-shape bg-gradient-orange text-white rounded-circle ml-2"
                  >
                    <i class="ni ni-calendar-grid-58"></i>
                  </div>
                </h2>
              </b-col>
              <b-col cols="4" class="text-right d-none d-sm-inline-block">
                <router-link to="/sessions">
                  All Sessions &raquo;
                </router-link>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <SessionTab
                  :collection="upcomingSessions"
                  hide-actions
                  hide-details
                />
              </b-col>
            </b-row>
            <b-row class="pb-4 text-center d-block d-sm-none">
              <b-col>
                <div class="text-sm">
                  <router-link to="/sessions">
                    All Sessions &raquo;
                  </router-link>
                </div>
              </b-col>
            </b-row>
          </card>
          <card body-classes="py-1" header-classes="py-3">
            <b-row align-v="center" slot="header">
              <b-col>
                <h2 class="mb-0">
                  My Tasks
                  <div
                    class="icon-md icon-shape bg-gradient-blue text-white rounded-circle ml-2"
                  >
                    <i class="ni ni-bullet-list-67"></i>
                  </div>
                </h2>
              </b-col>
              <b-col cols="4" class="text-right d-none d-sm-inline-block">
                <div class="text-sm">
                  <b-link
                    style="color: black"
                    @click="toggleTasks()"
                    class="pt-2 pl-4"
                  >
                    <p class="d-none d-sm-inline float-right mb-0">
                      {{ showTasks }}
                    </p></b-link
                  >
                </div>
              </b-col>
            </b-row>
            <template v-if="showTasks === 'Show Completed'"
              ><Tasks showIncomplete
            /></template>
            <template v-else><Tasks showCompleted/></template>
          </card>
          <div class="d-none d-sm-block">
            <Stats />
          </div>
        </b-col>
        <b-col sm="6">
          <ActiveClients />
        </b-col>
        <b-col class="d-block d-sm-none">
          <Stats />
        </b-col>
      </b-row>
    </b-container>
    <CompleteProfileModal />
  </div>
</template>

<script>
import SessionTab from '../Sections/Sessions/SessionTab.vue'
import ActiveClients from '../Sections/Dashboard/ActiveClients.vue'
import Tasks from '../Sections/Shared/Tasks.vue'
import Stats from '../Sections/Shared/Stats.vue'
import CompleteProfileModal from '../Modals/CompleteProfileModal.vue'

export default {
  props: {
    showAll: Boolean,
    showCompleted: Boolean,
    showIncomplete: Boolean
  },
  components: {
    ActiveClients,
    SessionTab,
    Tasks,
    Stats,
    CompleteProfileModal
  },
  data() {
    return {
      showTasks: 'Show Completed'
    }
  },
  computed: {
    upcomingSessions() {
      return this.$store.getters['sessions/activeSessions'].filter(
        session =>
          new Date(new Date(session.start_date_time).toDateString()) >=
          new Date(new Date().toDateString())
      )
    }
  },
  methods: {
    toggleTasks() {
      this.showTasks =
        this.showTasks === 'Show Completed'
          ? 'Hide Completed'
          : 'Show Completed'
    }
  }
}
</script>

<style>
.card-stats .h3 {
  font-size: 0.875rem;
}
</style>
