<template>
  <div>
    <base-header class="pb-6"></base-header>
    <b-container fluid class="mt--6 h-100">
      <b-row class="row justify-content-between d-flex">
        <b-col sm="3">
          <ProviderProfile />
        </b-col>
        <b-col sm="5">
          <ProviderRates />
        </b-col>
        <b-col sm="4">
          <StripeConnect />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ProviderProfile from '../Sections/Account/ProviderProfile.vue'
import ProviderRates from '../Sections/Account/ProviderRates.vue'
import StripeConnect from '../Sections/Account/PaymentsCard.vue'

export default {
  components: {
    ProviderProfile,
    ProviderRates,
    StripeConnect
  },
  data() {
    return {}
  }
}
</script>
<style></style>
