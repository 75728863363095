export default {
  defaultWarning(e) {
    return {
      message: `Error occurred:<br />${
        ~e.message?.indexOf('Validation failed')
          ? e.message.replace('Path', 'Field')
          : e.toString()
      }`,
      timeout: 7500,
      icon: 'fas fa-exclamation-triangle',
      type: 'warning'
    }
  },
  defaultSaveSuccess(msg) {
    return {
      message: msg || `Saved successfully`,
      timeout: 7500,
      icon: 'ni ni-check-bold',
      type: 'success'
    }
  }
}
