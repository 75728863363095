<template>
  <card>
    <b-row align-v="center" slot="header">
      <b-col>
        <h3 class="mb-0">
          Session Rates for {{ clientName }}
          <b-link
            @click="editRates = true"
            v-if="providerHasRates && !editRates"
            ><i class="fas fa-pen fa-xs text-gray"
          /></b-link>
        </h3>
      </b-col>
    </b-row>
    <template v-if="!editRates">
      <base-alert type="warning" v-if="!providerHasRates && providerReady">
        <i class="fas fa-exclamation-triangle" /> No session rates found,
        <router-link
          to="/account/settings"
          style="color: white; text-decoration: underline"
          >click here to set your session rates</router-link
        >
      </base-alert>
      <template v-for="(rate, i) in clientRates">
        <b-row :key="i">
          <b-col>
            <h6 class="heading-small text-muted mb-0">{{ rate.rate_name }}</h6>
            <div
              class="text-sm mb-3"
              :style="
                customRate(rate.rate, rate.rate_amount, rate.rate_duration) ===
                '(custom)'
                  ? 'font-weight: 600'
                  : ''
              "
            >
              ${{ rate.rate_amount || defaultRateAmount(rate.rate) }}
              {{ customRate(rate.rate, rate.rate_amount, rate.rate_duration) }}
              for {{ rate.rate_duration || defaultRateDuration(rate.rate) }} min
            </div>
            <p class="font-weight-400" v-if="i === clientRates.length - 1">
              Other session types are using
              <router-link to="/account/settings"
                >Settings &gt; Defaults Session Rates</router-link
              >.
            </p>
          </b-col>
        </b-row>
      </template>
      <b-row v-if="providerHasRates && !clientRates.length">
        <b-col>
          <p class="font-weight-400">
            Currently using
            <router-link to="/account/settings"
              >Settings &gt; Defaults Session Rates</router-link
            >. To set a custom rate for {{ clientName }}, click Edit (<i
              class="fas fa-pen fa-xs text-gray"
            />) above.
          </p>
        </b-col>
      </b-row>
    </template>
    <template v-if="editRates">
      <template v-for="(rate, i) in fieldRates">
        <b-row :key="'name' + i" class="mb-3">
          <b-col style="padding-right: 10px;">
            <h6 class="heading-small text-muted">{{ rate.rate_name }}<br /></h6>
            <b-input-group
              prepend="$"
              :append="
                customRate(rate.rate, rate.rate_amount, rate.rate_duration)
              "
            >
              <b-form-input
                v-model="rate.rate_amount"
                :placeholder="defaultRateAmount(rate.rate).toString()"
              />
            </b-input-group>
          </b-col>
          <b-col style="padding-left: 0;">
            <h6 class="heading-small text-muted"><br /></h6>
            <b-input-group prepend="for" append="min">
              <b-form-input
                v-model="rate.rate_duration"
                :placeholder="defaultRateDuration(rate.rate).toString()"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col class="d-flex justify-content-between">
          <base-button
            outline
            size="sm"
            type="primary"
            @click="clientRates.push({})"
            v-if="false"
            >Add Another</base-button
          >
          <base-button size="sm" type="primary" @click="handleEditRates"
            >Save</base-button
          >
        </b-col>
      </b-row>
    </template>
  </card>
</template>

<script>
import Vue from 'vue'
import notifications from '@/services/notifications'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'
import clientDetailsMixin from '@/mixins/clientDetailsMixin'

export default {
  props: ['clientId'],
  mixins: [providerDetailsMixin, clientDetailsMixin],
  data() {
    return {
      editRates: false,
      fields: []
    }
  },
  computed: {
    fieldRates: {
      get() {
        // see which of the 4 default rates are defined by provider
        const fields = [...this.clientRates]
        if (
          this.providerRates.find(x => x.rate === 'initial-consult') &&
          !fields.find(x => x.rate === 'initial-consult')
        ) {
          fields.push({
            rate: 'initial-consult',
            rate_name: 'Initial Consult'
          })
        }
        if (
          this.providerRates.find(x => x.rate === 'first-session') &&
          !fields.find(x => x.rate === 'first-session')
        ) {
          fields.push({
            rate: 'first-session',
            rate_name: 'First Session'
          })
        }
        if (
          this.providerRates.find(x => x.rate === 'regular-session') &&
          !fields.find(x => x.rate === 'regular-session')
        ) {
          fields.push({
            rate: 'regular-session',
            rate_name: 'Regular Session'
          })
        }
        if (
          this.providerRates.find(x => x.rate === 'regular-session-2') &&
          !fields.find(x => x.rate === 'regular-session-2')
        ) {
          fields.push({
            rate: 'regular-session-2',
            rate_name: 'Regular Session'
          })
        }
        return fields
      },
      set(value) {
        Vue.set(this, 'fields', value)
      }
    }
  },
  created() {
    try {
      this.$store.dispatch('provider/fetch')
      this.$store.dispatch('clients/fetchClientDetails', this.clientId)
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  methods: {
    customRate(rate, rate_amount, rate_duration) {
      return (rate_amount &&
        rate_amount?.toString() !== this.defaultRateAmount(rate)?.toString()) ||
        (rate_duration &&
          rate_duration?.toString() !==
            this.defaultRateDuration(rate)?.toString())
        ? '(custom)'
        : '(default)'
    },
    defaultRateAmount(rate) {
      if (rate) {
        return this.providerRates.find(x => x.rate === rate)?.rate_amount || ''
      }
      return ''
    },
    defaultRateDuration(rate) {
      if (rate) {
        return (
          this.providerRates.find(x => x.rate === rate)?.rate_duration || ''
        )
      }
      return ''
    },
    async handleEditRates() {
      // if either rate or duration left blank, fill in with default
      this.fieldRates
        .filter(x => x.rate_amount || x.rate_duration)
        .forEach(x => {
          if (!x.rate_amount) {
            x.rate_amount = this.defaultRateAmount(x.rate)
          }
          if (!x.rate_duration) {
            x.rate_duration = this.defaultRateDuration(x.rate)
          }
        })

      // cleanup blank items in clientRates, leaving empty array
      this.clientRates = this.fieldRates.filter(
        x => x.rate_amount && x.rate_duration
      )

      try {
        await this.$store.dispatch('clients/save', this.clientDetails)
        this.editRates = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>
