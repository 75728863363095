<template>
  <card class="providerprofile">
    <b-row align-v="center" slot="header">
      <b-col>
        <h3 class="mb-0">Provider Profile</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Name</h6>
        <div class="text-sm mb-3">
          <template v-if="!editName">
            {{ providerName || 'Add name...' }}
            <b-link @click="editName = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <div class="justify-content-between d-flex">
            <base-input
              placeholder="First name"
              input-classes="form-control-sm text-sm"
              v-model="providerDetails.first_name"
              v-if="editName"
              :autofocus="editName"
              @keyup.enter="handleInlineEdit('editName')"
            />
            <base-input
              placeholder="Last name"
              input-classes="form-control-sm text-sm ml-1 mr-1"
              v-model="providerDetails.last_name"
              v-if="editName"
              @keyup.enter="handleInlineEdit('editName')"
            />
          </div>
          <template v-if="!providerDetails.business_name">
            <b-badge variant="success">Shown to Clients</b-badge>
            <div class="small pt-1 text-gray">
              or use Business Name below
            </div>
          </template>
          <base-button
            size="sm"
            type="primary"
            v-if="editName"
            class="mt-2"
            @click="handleInlineEdit('editName')"
            >Save</base-button
          >
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Phone</h6>
        <div class="text-sm mb-3">
          <template v-if="!editPhone">
            {{ providerPhone || 'Add phone...' }}
            <b-link @click="editPhone = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="(408) 555-1212"
            input-classes="form-control-sm text-sm"
            v-model="providerDetails.phone"
            v-if="editPhone"
            :autofocus="editPhone"
            @keyup.enter="handleInlineEdit('editPhone')"
            @blur="handleInlineEdit('editPhone')"
          />
          <div v-if="!providerDetails.business_phone">
            <b-badge variant="success">Shown to Clients</b-badge>
            <div class="small pt-1 text-gray">
              or use Business Phone below
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Email</h6>
        <div class="text-sm mb-3">
          <template v-if="!editEmail">
            {{ providerEmail }}
            <b-link @click="editEmail = true" v-if="false"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="someone@example.com"
            input-classes="form-control-sm text-sm"
            v-model="providerDetails.email"
            v-if="editEmail"
            :autofocus="editEmail"
            @keyup.enter="handleInlineEdit('editEmail')"
            @blur="handleInlineEdit('editEmail')"
          />
          <div v-if="!providerDetails.business_email">
            <b-badge variant="success">Shown to Clients</b-badge>
            <div class="small pt-1 text-gray">
              or use Business Email below
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Address</h6>
        <div class="text-sm mb-3">
          <div style="white-space: pre-line;" v-if="!editLocation">
            {{ providerLocationWithAddress2 || 'No location set' }}
            <b-link @click="handleClickEditLocation"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </div>
          <vue-google-autocomplete
            ref="location"
            id="map"
            placeholder="Start typing address..."
            classname="form-control form-control-sm text-sm"
            :country="['us', 'ca']"
            v-on:placechanged="handleEditLocationData"
            v-if="editLocation"
            @keyup.enter="handleEditLocation"
          />
          <base-input
            placeholder="Apt, suite, ... (optional)"
            input-classes="form-control-sm text-sm mt-1"
            v-model="providerAddress2"
            v-if="editLocation"
            @keyup.enter="handleInlineEdit('editLocation')"
          />
          <base-button
            size="sm"
            type="primary"
            v-if="editLocation"
            class="mt-2"
            @click="handleInlineEdit('editLocation')"
            >Save</base-button
          >
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Timezone (Detected)</h6>
        <div class="text-sm mb-3">
          {{ providerDetails.timezone }}
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Business Name</h6>
        <div class="text-sm mb-3">
          <template v-if="!editBusinessName">
            {{ providerDetails.business_name || 'Add business name...' }}
            <b-link @click="editBusinessName = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="Bruce Wayne, LPC"
            input-classes="form-control-sm text-sm mb-0"
            v-model="providerDetails.business_name"
            v-if="editBusinessName"
            :autofocus="editBusinessName"
            @keyup.enter="handleInlineEdit('editBusinessName')"
            @blur="handleInlineEdit('editBusinessName')"
          />
          <template v-if="providerDetails.business_name">
            <b-badge variant="success">Shown to Clients</b-badge>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Business Legal Name</h6>
        <div class="text-sm mb-3">
          <template v-if="!editBusinessLegalName">
            {{
              providerDetails.business_legal_name ||
                'Add business legal name...'
            }}
            <b-link @click="editBusinessLegalName = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="Wayne Enterprises LLC"
            input-classes="form-control-sm text-sm"
            v-model="providerDetails.business_legal_name"
            v-if="editBusinessLegalName"
            :autofocus="editBusinessLegalName"
            @keyup.enter="handleInlineEdit('editBusinessLegalName')"
            @blur="handleInlineEdit('editBusinessLegalName')"
          />
          <div
            class="small pt-1 text-gray"
            v-if="providerDetails.business_legal_name"
          >
            Used only for client invoices
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Business Phone</h6>
        <div class="text-sm mb-3">
          <template v-if="!editBusinessPhone">
            {{ providerBusinessPhone || 'Add business phone...' }}
            <b-link @click="editBusinessPhone = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="(408) 555-1212"
            input-classes="form-control-sm text-sm"
            v-model="providerDetails.business_phone"
            v-if="editBusinessPhone"
            :autofocus="editBusinessPhone"
            @keyup.enter="handleInlineEdit('editBusinessPhone')"
            @blur="handleInlineEdit('editBusinessPhone')"
          />
          <template v-if="providerDetails.business_phone">
            <b-badge variant="success">Shown to Clients</b-badge>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Business Email</h6>
        <div class="text-sm mb-3">
          <template v-if="!editBusinessEmail">
            {{ providerBusinessEmail || 'Add business email...' }}
            <b-link @click="editBusinessEmail = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="someone@example.com"
            input-classes="form-control-sm text-sm"
            v-model="providerDetails.business_email"
            v-if="editBusinessEmail"
            :autofocus="editBusinessEmail"
            @keyup.enter="handleInlineEdit('editBusinessEmail')"
            @blur="handleInlineEdit('editBusinessEmail')"
          />
          <template v-if="providerDetails.business_email">
            <b-badge variant="success">Shown to Clients</b-badge>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Office Address</h6>
        <div class="text-sm mb-3">
          <div style="white-space: pre-line;" v-if="!editBusinessLocation">
            {{ providerBusinessLocationWithAddress2 || 'No location set' }}
            <b-link @click="handleClickEditBusinessLocation"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </div>
          <vue-google-autocomplete
            ref="business_location"
            id="business_map"
            placeholder="Start typing address..."
            classname="form-control form-control-sm text-sm"
            :country="['us', 'ca']"
            v-on:placechanged="handleEditBusinessLocationData"
            v-if="editBusinessLocation"
            @keyup.enter="handleEditBusinessLocation"
          />
          <base-input
            placeholder="Apt, suite, ... (optional)"
            input-classes="form-control-sm text-sm mt-1"
            v-model="providerBusinessAddress2"
            v-if="editBusinessLocation"
            @keyup.enter="handleInlineEdit('editBusinessLocation')"
          />
          <base-button
            size="sm"
            type="primary"
            v-if="editBusinessLocation"
            class="mt-2"
            @click="handleInlineEdit('editBusinessLocation')"
            >Save</base-button
          >
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Video Link</h6>
        <div class="text-sm mb-3">
          <template v-if="!editVideoLink">
            <a :href="providerDetails.video_link" target="blank">
              {{ providerDetails.video_link || 'Add a video link...' }}
            </a>
            <b-link @click="editVideoLink = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="https://zoom.us/j/1234567890"
            input-classes="form-control-sm text-sm"
            v-model="providerDetails.video_link"
            v-if="editVideoLink"
            :autofocus="editVideoLink"
            @keyup.enter="handleInlineEdit('editVideoLink')"
            @blur="handleInlineEdit('editVideoLink')"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Default Session Location</h6>
        <div class="text-sm mb-3">
          <template v-if="!editSessionLocation">
            <template
              v-if="
                providerDetails.session_location &&
                  ~providerDetails.session_location.indexOf('http')
              "
            >
              <a :href="providerDetails.session_location" target="blank">
                {{ providerDetails.session_location }}
              </a>
            </template>
            <template v-else>
              {{ providerDetails.session_location || 'n/a' }}
            </template>
            <b-link @click="editSessionLocation = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <b-form-group v-if="editSessionLocation">
            <b-form-radio
              class="custom-control-inline"
              name="session_location"
              v-model="providerDetails.session_location"
              @change="handleInlineEdit('editSessionLocation')"
              :value="
                providerBusinessLocationWithAddress2 &&
                  providerBusinessLocationWithAddress2.replace(/\n/g, ', ')
              "
            >
              Office Address
            </b-form-radio>
            <b-form-radio
              class="custom-control-inline"
              name="session_location"
              v-model="providerDetails.session_location"
              @change="handleInlineEdit('editSessionLocation')"
              :value="providerDetails.video_link || ''"
            >
              Video Link
            </b-form-radio>
          </b-form-group>
          <template v-if="providerDetails.session_location">
            <b-badge variant="success">Shown to Clients</b-badge><br />
          </template>
          <base-button
            size="sm"
            type="secondary"
            class="mt-2"
            v-if="editSessionLocation"
            @click="editSessionLocation = false"
            >Cancel</base-button
          >
        </div>
      </b-col>
    </b-row>
  </card>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import notifications from '@/services/notifications'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'

export default {
  components: {
    VueGoogleAutocomplete
  },
  mixins: [providerDetailsMixin],
  data() {
    return {
      editName: false,
      editPhone: false,
      editEmail: false,
      editLocation: false,
      editBusinessName: false,
      editBusinessLegalName: false,
      editBusinessPhone: false,
      editBusinessEmail: false,
      editBusinessLocation: false,
      editVideoLink: false,
      editSessionLocation: false
    }
  },
  created() {
    this.$store.dispatch('provider/fetch')
  },
  mounted() {
    if (this.providerReady) {
      // nav to page
      this.detectTimezone()
    } else {
      // first load
      this.$watch('providerReady', () => {
        this.detectTimezone()
      })
    }
  },
  methods: {
    detectTimezone() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (
        !this.providerDetails.timezone ||
        this.providerDetails.timezone !== timezone
      ) {
        this.providerDetails.timezone = timezone
        this.handleInlineEdit()
      }
    },
    async handleInlineEdit(flag) {
      try {
        await this.$store.dispatch('provider/save', this.providerDetails)
        this[flag] = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleEditLocation() {
      // user typed in location, no location fields :(
      this.providerDetails.location = {
        location: this.$refs.location.autocompleteText
      }
      try {
        await this.$store.dispatch('provider/save', this.providerDetails)
        this.editLocation = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleEditBusinessLocation() {
      // user typed in location, no location fields :(
      this.providerDetails.business_location = {
        location: this.$refs.business_location.autocompleteText
      }
      try {
        await this.$store.dispatch('provider/save', this.providerDetails)
        this.editBusinessLocation = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleEditLocationData(address) {
      // google autocomplete location...eg:
      // {
      //     "street_number": "1",
      //     "route": "Infinite Loop",
      //     "locality": "Cupertino",
      //     "administrative_area_level_2": "Santa Clara County",
      //     "administrative_area_level_1": "CA",
      //     "country": "United States",
      //     "postal_code": "95014",
      //     "latitude": 37.3318641,
      //     "longitude": -122.0302537
      // }
      this.providerDetails.location = {
        address: `${address.street_number} ${address.route}`,
        address2: this.providerDetails.location?.address2,
        city: address.locality,
        state: address.administrative_area_level_1,
        zip: address.postal_code,
        country:
          address.country === 'United States'
            ? 'US'
            : address.country === 'Canada'
            ? 'CA'
            : address.country
      }
      try {
        await this.$store.dispatch('provider/save', this.providerDetails)
        // this.editLocation = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleEditBusinessLocationData(address) {
      // google autocomplete location
      this.providerDetails.business_location = {
        address: `${address.street_number} ${address.route}`,
        address2: this.providerDetails.business_location?.address2,
        city: address.locality,
        state: address.administrative_area_level_1,
        zip: address.postal_code,
        country:
          address.country === 'United States'
            ? 'US'
            : address.country === 'Canada'
            ? 'CA'
            : address.country
      }
      try {
        await this.$store.dispatch('provider/save', this.providerDetails)
        // this.editBusinessLocation = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    handleClickEditLocation() {
      this.editLocation = true
      setImmediate(() => {
        this.$refs.location.update(this.providerLocation?.replace(/\n/g, ', '))
      })
    },
    handleClickEditBusinessLocation() {
      this.editBusinessLocation = true
      setImmediate(() => {
        this.$refs.business_location.update(
          this.providerBusinessLocation?.replace(/\n/g, ', ')
        )
      })
    }
  }
}
</script>

<style>
.providerprofile .form-group {
  margin-bottom: 0;
}
</style>
