<template>
  <modal :show.sync="showModal" size="lg" body-classes="p-0" hide-footer>
    <h6 slot="header" class="modal-title">Email Client Notes</h6>

    <div
      v-if="sessionId"
      style="padding-right: 25px; padding-left: 25px; padding-top: 5px"
    >
      <b-row>
        <b-col>
          <base-input label="To" class="mb-0">
            <el-select
              v-model="selects.multiple"
              multiple
              filterable
              placeholder="Multiple select"
            >
              <el-option
                v-for="option in selectOptions"
                :key="option.label"
                :label="option.label"
                :value="option.value"
              >
              </el-option>
            </el-select>
          </base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            type="text"
            label="Subject"
            placeholder="Subject"
            v-model="user.subject"
          >
          </base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            label="URL"
            type="url"
            placeholder="https://drive.google.com/"
            v-model="user.link"
            id="example-url-input"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Message"
            label-class="form-control-label"
            class="mb-0"
          >
            <html-editor
              size="sm"
              v-model="client_notes"
              placeholder="Send a message to client"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="text-center" style="margin-top:20px; margin-bottom:20px">
      <base-button type="primary" @click="showModal = false">
        Send
      </base-button>
    </div>
  </modal>
</template>

<script>
import HtmlEditor from '@/components/Inputs/HtmlEditor.vue'
import sessionModalMixin from '@/mixins/sessionModalMixin'

export default {
  props: ['sessionId'],
  components: {
    HtmlEditor
  },
  mixins: [sessionModalMixin],
  data() {
    return {
      client_notes: '',
      selectOptions: [
        {
          label: 'Violet Choi',
          value: 'violetchoi@gmail.com'
        },
        {
          label: 'Mother',
          value: 'ellachoi@gmail.com'
        },
        {
          label: 'Father',
          value: 'bobchoi@gmail.com'
        }
      ],
      selects: {
        simple: 'violetchoi@gmail.com',
        multiple: ['violetchoi@gmail.com']
      },
      user: {
        name: 'Violet Choi',
        sessionType: '1st Consult',
        time: '11:00 am',
        duration: '11:30 am',
        rate: '$125',
        billCode: '',
        location: '123 Timber Lane'
      }
    }
  },
  methods: {}
}
</script>
