<template>
  <div class="tasks">
    <b-row>
      <b-col>
        <b-list-group flush data-toggle="checklist" v-if="showIncomplete">
          <b-list-group-item
            class="checklist-entry flex-column align-items-start py-3 px-0"
            v-for="task in incompleteTasks"
            :key="task.task"
          >
            <div
              class="checklist-item"
              :class="{
                'checklist-item-checked': task.marked_done,
                'checklist-item-primary': 'primary'
              }"
            >
              <div class="checklist-info">
                <h4
                  v-if="editTask !== task.task"
                  class="checklist-title mb-0 font-weight-400"
                >
                  {{ task.title }}
                  <b-link @click="editTask = task.task"
                    ><i class="fas fa-pen fa-xs text-gray"
                  /></b-link>
                </h4>
                <base-input
                  placeholder="Edit task..."
                  input-classes="form-control-sm text-sm"
                  v-model="task.title"
                  v-if="editTask === task.task"
                  :autofocus="editTask === task.task"
                  @keyup.enter="handleEditTask(task)"
                  @blur="handleEditTask(task)"
                />
                <!-- <small></small> -->
              </div>
              <div>
                <b-form-checkbox
                  v-if="editTask !== task.task"
                  :checked="task.done"
                  @change="handleDoneTask(task, $event)"
                  class="custom-checkbox-primary"
                />
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
        <b-list-group flush data-toggle="checklist" v-if="showCompleted">
          <b-list-group-item
            class="checklist-entry flex-column align-items-start py-3 px-0"
            v-for="task in completedTasks"
            :key="task.task"
          >
            <div
              class="checklist-item"
              :class="{
                'checklist-item-checked': task.marked_done,
                'checklist-item-primary': 'primary'
              }"
            >
              <div class="checklist-info">
                <h4
                  v-if="editTask !== task.task"
                  class="checklist-title mb-0 font-weight-400"
                >
                  {{ task.title }}
                  <b-link @click="editTask = task.task"
                    ><i class="fas fa-pen fa-xs text-gray"
                  /></b-link>
                </h4>
                <base-input
                  placeholder="Edit task..."
                  input-classes="form-control-sm text-sm"
                  v-model="task.title"
                  v-if="editTask === task.task"
                  :autofocus="editTask === task.task"
                  @keyup.enter="handleEditTask(task)"
                  @blur="handleEditTask(task)"
                />
              </div>
              <div>
                <b-form-checkbox
                  v-if="editTask !== task.task"
                  :checked="task.done"
                  @change="handleDoneTask(task, $event)"
                  class="custom-checkbox-primary"
                />
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="text-sm my-3">
          <template v-if="editTask !== '__NEW__'">
            <b-link @click="editTask = '__NEW__'"
              >Add new task... <i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="Add new task..."
            input-classes="form-control-sm text-sm"
            v-model="fields.title"
            v-if="editTask === '__NEW__'"
            :autofocus="editTask === '__NEW__'"
            @keyup.enter="handleEditTask(fields)"
            @blur="handleEditTask(fields)"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import notifications from '@/services/notifications'

export default {
  // props: ['clientId'], optional
  props: {
    clientId: {
      type: String,
      value: 'clientId'
    },
    showAll: Boolean,
    showCompleted: Boolean,
    showIncomplete: Boolean
  },
  data() {
    return {
      editTask: null,
      fields: {}
    }
  },
  computed: {
    incompleteTasks() {
      return this.$store.getters['tasks/incompleteTasks'].filter(
        task => task.client === this.clientId // if null, it'll show provider tasks
      )
    },
    completedTasks() {
      return this.$store.getters['tasks/completedTasks'].filter(
        task => task.client === this.clientId // if null, it'll show provider tasks
      )
    },
    allTasks() {
      return this.$store.getters['tasks/allTasks'].filter(
        task => task.client === this.clientId // if null, it'll show provider tasks
      )
    }
  },
  created() {
    try {
      this.$store.dispatch('tasks/fetch')
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  methods: {
    async handleEditTask(task) {
      // skip blank titles
      if (!task.title && !task.task) {
        this.editTask = null
        this.fields = {}
        return
      }
      try {
        if (this.clientId) {
          task.client = this.clientId
        }
        if (task.title) {
          await this.$store.dispatch('tasks/save', task)
        } else {
          // blank title = delete
          await this.$store.dispatch('tasks/delete', task.task)
        }
        this.editTask = null
        this.fields = {}
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleDoneTask(task, checked) {
      task.marked_done = checked
      try {
        await this.$store.dispatch('tasks/updateDone', {
          id: task.task,
          done: checked
        })
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>

<style>
.tasks .form-group {
  margin-bottom: 0;
}
</style>
