<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6">
      <card class="no-border-card" body-classes="px-0 pb-0">
        <template slot="header">
          <h1 class="mb-0 d-none d-sm-inline-flex">
            Sessions
          </h1>
          <base-button
            icon
            type="primary"
            class="ml-5 mb-2 d-none d-sm-inline"
            @click="handleAdd"
            v-b-tooltip.hover.top
            :title="
              !providerHasClients
                ? 'Please add at least 1 client before creating a session'
                : !providerHasRates
                ? 'Click Settings > Default Session Rates and define 1 session rate first'
                : ''
            "
          >
            <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
            <span class="btn-inner--text">Add New</span>
          </base-button>
          <base-button
            outline
            icon
            type="default"
            class="ml-1 mb-2 d-none d-sm-inline"
            @click="handleSubscribe"
          >
            <i class="far fa-calendar-plus mr-1" />
            Subscribe</base-button
          >
          <b-row class="d-block d-sm-none">
            <b-col class="px-1">
              <h1 class="d-inline-flex">
                Sessions
              </h1>
              <base-button
                icon
                type="primary"
                size="sm"
                class="ml-3"
                @click="handleAdd"
                v-b-tooltip.hover.top
                :title="
                  !providerHasClients
                    ? 'Please add at least 1 client before creating a session'
                    : !providerHasRates
                    ? 'Click Settings > Default Session Rates and define 1 session rate first'
                    : ''
                "
              >
                <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
                <span class="btn-inner--text">Add New</span>
              </base-button>
              <base-button
                outline
                icon
                type="default"
                size="sm"
                class="ml-1"
                @click="handleSubscribe"
              >
                <i class="far fa-calendar-plus mr-1" />
                Subscribe</base-button
              >
            </b-col>
          </b-row>
        </template>
        <b-col
          cols="12"
          class="d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary"
            v-model="filterClient"
            @change="handleClient"
            placeholder="Filter by client..."
            clearable
            filterable
          >
            <el-option
              v-for="client in clients"
              :key="client.client"
              :label="client.first_name + ' ' + client.last_name"
              :value="client.client"
            >
            </el-option>
          </el-select>
          <!-- <base-input
            v-model="searchQuery"
            prepend-icon="fas fa-search"
            placeholder="Search name..."
          >
          </base-input> -->
          <el-select
            class="select-primary pagination-select"
            v-model="perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in perPageOptions"
              :key="item"
              :label="item + ' per page'"
              :value="item"
            >
            </el-option>
          </el-select>
        </b-col>
        <b-tabs pills>
          <b-tab
            v-for="tab in tabs"
            :key="tab.title"
            :title="tab.title"
            :active="tab.title.toLowerCase() === ($route.query.tab || 'active')"
          >
            <SessionTab
              :collection="tab.collection"
              :descending="tab.descending"
              :searchQueryField="searchQuery"
              :perPageField="perPage"
              :filterClient="filterClient"
              grouped
              @edit="handleEdit"
              @reschedule="handleReschedule"
              @complete="handleComplete"
              @missed="handleMissed"
              @canceled="handleCanceled"
              @paid="handlePaid"
              @email="handleEmail"
              @subscribe="handleSubscribe"
              @searchQuery="handleSearchQuery"
            />
          </b-tab>
        </b-tabs>
      </card>
    </b-container>

    <SessionAddEditModal :mode="addEditMode" :sessionId.sync="addEditId" />
    <SessionCompleteModal :sessionId.sync="completeId" />
    <SessionMissedModal :sessionId.sync="missedId" />
    <SessionCanceledModal :sessionId.sync="canceledId" />
    <SessionPaidModal :sessionId.sync="paidId" />
    <SessionEmailModal :sessionId.sync="emailId" />
    <SessionSubscribeModal :show.sync="showSubscribe" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import notifications from '@/services/notifications'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'
import SessionTab from '../Sections/Sessions/SessionTab.vue'
import SessionAddEditModal from '../Modals/SessionAddEditModal.vue'
import SessionCompleteModal from '../Modals/SessionCompleteModal.vue'
import SessionMissedModal from '../Modals/SessionMissedModal.vue'
import SessionCanceledModal from '../Modals/SessionCanceledModal.vue'
import SessionPaidModal from '../Modals/SessionPaidModal.vue'
import SessionEmailModal from '../Modals/SessionEmailModal.vue'
import SessionSubscribeModal from '../Modals/SessionSubscribeModal.vue'

export default {
  components: {
    SessionTab,
    SessionAddEditModal,
    SessionCompleteModal,
    SessionMissedModal,
    SessionCanceledModal,
    SessionPaidModal,
    SessionEmailModal,
    SessionSubscribeModal
  },
  mixins: [providerDetailsMixin],
  data() {
    return {
      showSubscribe: null,
      addEditMode: null,
      addEditId: null,
      completeId: null,
      deleteId: null,
      missedId: null,
      canceledId: null,
      paidId: null,
      emailId: null,
      searchQuery: '',
      filterClient: this.$route.query.client,
      perPage: 10,
      perPageOptions: [5, 10, 25, 50]
    }
  },
  computed: {
    ...mapGetters('sessions', [
      'activeSessions',
      'doneSessions',
      'unpaidSessions'
    ]),
    tabs() {
      return [
        { title: 'Active', collection: this.activeSessions },
        { title: 'Done', collection: this.doneSessions, descending: true },
        { title: 'Unpaid', collection: this.unpaidSessions, descending: true }
      ]
    },
    allowAddNewSession() {
      return this.providerHasClients && this.providerHasRates
    },
    providerHasClients() {
      return this.$store.state.clients.clients.length
    },
    clients() {
      return this.$store.getters['clients/activeClients']
    }
  },
  created() {
    try {
      this.$store.dispatch('provider/fetch')
      this.$store.dispatch('clients/fetch')
      this.$store.dispatch('sessiongroups/fetch')
      this.$store.dispatch('sessions/fetch')
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  mounted() {
    if (this.$route.hash === '#add') {
      this.handleAdd()
    }
  },
  methods: {
    handleClient() {
      this.$router.push({
        path: '/sessions',
        query: { client: this.filterClient }
      })
    },
    handleSubscribe() {
      this.showSubscribe = true
    },
    handleAdd() {
      if (!this.allowAddNewSession) return
      this.addEditMode = 'add'
      this.addEditId = '__NEW__'
    },
    handleEdit(sessionId) {
      try {
        this.$store.dispatch('sessions/fetchSessionDetails', sessionId)
        this.addEditMode = 'edit'
        this.addEditId = sessionId
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    handleReschedule(sessionId) {
      try {
        this.$store.dispatch('sessions/fetchSessionDetails', sessionId)
        this.addEditMode = 'reschedule'
        this.addEditId = sessionId
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    handleComplete(sessionId) {
      this.completeId = sessionId
    },
    handleMissed(sessionId) {
      this.missedId = sessionId
    },
    handleCanceled(sessionId) {
      this.canceledId = sessionId
    },
    handlePaid(sessionId) {
      this.paidId = sessionId
    },
    handleEmail(sessionId) {
      this.emailId = sessionId
    },
    handleSearchQuery(query) {
      // mainly to help clear query after deletion from sessioncard
      this.searchQuery = query
    }
  }
}
</script>
