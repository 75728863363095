<template>
  <modal :show.sync="showModal" body-classes="p-0" hide-footer>
    <h6 slot="header" class="modal-title">
      Subscribe to your Zenclear Calendar
    </h6>
    <card
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-5"
      class="border-0 mb-0"
    >
      <p>
        View your upcoming sessions, classes, and see them in your regular
        calendar app! For iPhone, click the link below:
      </p>
      <b-link :href="feedUrl.replace('https://', 'webcal://')"
        >Subscribe to calendar</b-link
      ><br /><br />
      <p>
        If you use another calendar app such as Google Calendar, copy the
        calendar URL below and subscribe to it.
      </p>
      <div class="mt-3 mb-4">
        <b-input-group>
          <b-form-input
            type="url"
            v-model="feedUrl"
            v-on:focus="$event.target.select()"
            ref="myinput"
            readonly
          ></b-form-input>

          <b-input-group-append>
            <b-button variant="outline-default" @click="copy()">Copy</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>

      <b-link v-b-toggle.accordion-1>
        How often does subscribed calendars refresh?
      </b-link>
      <b-collapse id="accordion-1">
        <p>
          Largely depends on the app you use. Unfortunately, it is outside of
          Zenclear's control. We update every 1 minute on our end.
        </p>
        <ul class="description font-weight-300">
          <li>iPhone updates upon app startup &amp; every 1-3 hours</li>
          <li>
            MacOS depends on refresh interval chosen. You can choose as fast as
            every 5 minutes
          </li>
          <li>Google normally updates every 18-24 hours</li>
          <li>
            Outlook updates upon app startup &amp; every 1-3 hours
          </li>
          <li>
            Outlook.com updates every 3 hours (sometimes could be as long as 24
            hours)
          </li>
          <li>Yahoo! updates every 8-12 hours</li>
        </ul>
      </b-collapse>
      <br />
      <br />
    </card>
  </modal>
</template>

<script>
import modalMixin from '@/mixins/modalMixin'

export default {
  props: ['show'],
  mixins: [modalMixin],
  data() {
    return {
      feedUrl: ''
    }
  },
  mounted() {
    if (this.$auth.dataLoaded) {
      this.feedUrl = `${process.env.VUE_APP_API_CALENDARURL}/c/${this.$auth.user.data.meta?.cal?.token}`
    } else {
      this.$watch('$auth.dataLoaded', () => {
        this.feedUrl = `${process.env.VUE_APP_API_CALENDARURL}/c/${this.$auth.user.data.meta?.cal?.token}`
      })
    }
  },
  methods: {
    copy() {
      navigator.clipboard
        .writeText(this.feedUrl)
        .then(() => {
          console.log('Text is on the clipboard.')
        })
        .catch(e => {
          console.error(e)
        })
    }
  }
}
</script>
