function convertTZ(date, tzString) {
  return new Date(
    new Date(date).toLocaleString('en-US', { timeZone: tzString })
  )
}

function convertLocale(date) {
  // ensure not already locale
  if (typeof date === 'string' && date.substring(date.length - 1) !== 'Z') {
    return date
  }
  let d = new Date(date)
  return (
    `${d.getFullYear()}-` +
    `${d.getMonth() < 9 ? '0' : ''}${d.getMonth() + 1}-` +
    `${d.getDate() < 10 ? '0' : ''}${d.getDate()}T` +
    `${d.toLocaleTimeString('en-US', { hour12: false })}`
  )
}

export { convertTZ, convertLocale }
