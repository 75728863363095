<template>
  <card no-body class="widget-calendar mb-0 d-flex">
    <b-card-body
      no-body
      class="mb-0"
      style="padding: 10px; padding-left: 20px; padding-right: 20px; border-bottom: 1px solid #e6e7eb"
    >
      <b-row v-if="sessionClient" align-v="center" class="mr-0">
        <b-col class="ml-2 mr--2" cols="auto" v-if="!hideAvatars">
          <router-link :to="`/clients/${session.client}`">
            <user-avatar
              :img="session.img"
              :name="clientName"
              :email="clientEmail"
            />
          </router-link>
        </b-col>
        <b-col class="ml-1" v-b-toggle="'accordion-' + session.session">
          <h3 v-if="!grouped" class="text-dark d-block">
            {{ sessionDate }}
          </h3>
          <h3 class="text-dark d-block d-sm-none">
            {{ sessionStartTime }} - {{ sessionEndTime }} <br /><small
              >({{ rateDuration }} min)</small
            >
          </h3>
          <h3 class="text-dark d-none d-sm-block">
            {{ sessionStartTime }} - {{ sessionEndTime }}
            <small>({{ rateDuration }} min)</small>
          </h3>
          <small class="text-dark d-block" style="font-size: 1rem;">
            <template v-if="!hideNames">
              <router-link :to="`/clients/${session.client}`">{{
                clientName
              }}</router-link>
              <small
                class="ml-1"
                style="color: #525f7f"
                v-if="sessionClient.level"
              >
                ({{ sessionClient.level }})
              </small>
              <br />
            </template>
            {{ rateName }}
            <template v-if="session.repeat">
              ({{ session.sessiongroup_session_index + 1 }} of
              {{ sessionGroup && sessionGroup.repeat_options.end_count + 1 }})
            </template>
            <br />
            <a
              :href="session.location"
              target="blank"
              v-if="
                session.location &&
                  (~session.location.indexOf('http:') ||
                    ~session.location.indexOf('https:'))
              "
            >
              {{ session.location }}
            </a>
            <template v-else>
              {{ session.location }}
            </template>
          </small>
        </b-col>
        <b-col cols="1" class="px-0 d-block d-sm-none">
          <span align="center">
            <base-button
              link
              role="tab"
              v-b-toggle="'accordion-' + session.session"
              style="background-color: white; box-shadow: none; border: none; padding: 0;"
            >
              <i class="fas fa-chevron-down"></i>
            </base-button>
          </span>
        </b-col>
        <template v-if="!hideActions">
          <b-col
            v-if="isScheduled"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              @click="$emit('reschedule', session.session)"
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background: #fdeee0; color: #e1b77f;"
            >
              Reschedule
            </base-button>
          </b-col>
          <b-col
            v-if="isScheduled"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              @click="$emit('reschedule', session.session)"
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background: #fdeee0; color: #e1b77f;"
            >
              Reschedule
            </base-button>
          </b-col>
          <b-col
            v-if="isNoCharge"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: gray"
            >
              No Charge
            </base-button>
          </b-col>
          <b-col
            v-if="isNoCharge"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: gray"
            >
              No Charge
            </base-button>
          </b-col>
          <b-col
            v-if="featureFlag.stripe_payments && isUnpaid && isMissed"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background-color: #b0eed3; color: #1aae6f;"
              @click="$emit('missed', session.session)"
              >Charge</base-button
            >
          </b-col>
          <b-col
            v-if="featureFlag.stripe_payments && isUnpaid && isCanceled"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background-color: #b0eed3; color: #1aae6f;"
              @click="$emit('canceled', session.session)"
              >Charge</base-button
            >
          </b-col>
          <b-col
            v-if="featureFlag.stripe_payments && isUnpaid && isMissed"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background-color: #b0eed3; color: #1aae6f;"
              @click="$emit('missed', session.session)"
              >Charge</base-button
            >
          </b-col>
          <b-col
            v-if="featureFlag.stripe_payments && isUnpaid && isCanceled"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background-color: #b0eed3; color: #1aae6f;"
              @click="$emit('canceled', session.session)"
              >Charge</base-button
            >
          </b-col>
          <b-col
            v-if="featureFlag.stripe_payments && isUnpaid && isCompleted"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background-color: #b0eed3; color: #1aae6f;"
              @click="$emit('complete', session.session)"
              >Charge</base-button
            >
          </b-col>
          <b-col
            v-if="featureFlag.stripe_payments && isUnpaid && isCompleted"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background-color: #b0eed3; color: #1aae6f;"
              @click="$emit('complete', session.session)"
              >Charge</base-button
            >
          </b-col>
          <b-col
            v-if="isUnpaid && !isScheduled"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: #f70012;"
              >Unpaid ✖</base-button
            >
          </b-col>
          <b-col
            v-if="isUnpaid && !isScheduled"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: #f70012;"
              >Unpaid ✖</base-button
            >
          </b-col>
          <b-col
            v-if="isPendingPayment && !isScheduled"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 165px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc;"
              >Pending Payment</base-button
            >
          </b-col>
          <b-col
            v-if="isPendingPayment && !isScheduled"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 165px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc;"
              >Pending Payment</base-button
            >
          </b-col>
          <b-col
            v-if="isPaid"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-none d-md-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: #1aae6f"
            >
              Paid ✓
            </base-button>
          </b-col>
          <b-col
            v-if="isPaid"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: #1aae6f"
            >
              Paid ✓
            </base-button>
          </b-col>
          <b-col
            v-if="isScheduled"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background-color: #b0eed3; color: #1aae6f;"
              @click="$emit('complete', session.session)"
              >Complete</base-button
            >
          </b-col>
          <b-col
            v-if="isScheduled"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; background-color: #b0eed3; color: #1aae6f;"
              @click="$emit('complete', session.session)"
              >Complete</base-button
            >
          </b-col>
          <b-col
            v-if="isMissed"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: gray"
            >
              Missed
            </base-button>
          </b-col>
          <b-col
            v-if="isMissed"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: gray"
            >
              Missed
            </base-button>
          </b-col>
          <b-col
            v-if="isCanceled"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: gray"
            >
              Canceled
            </base-button>
          </b-col>
          <b-col
            v-if="isCanceled"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 125px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: gray"
            >
              Canceled
            </base-button>
          </b-col>
          <b-col
            v-if="isCompleted"
            s="3"
            md="2"
            lg="auto"
            class="text-center d-none d-sm-block"
            style="margin-top: 10px"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 135px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: gray"
            >
              Completed ✓
            </base-button>
          </b-col>
          <b-col
            v-if="isCompleted"
            class="text-center d-block d-sm-none"
            style="margin-top: 10px; margin-left: 80px;"
          >
            <base-button
              type="secondary"
              disabled
              block
              size="md"
              style="width: 135px; height: 40px; border-radius: 40px; border: none; box-shadow: none; background-color: #f7f8fc; color: gray"
            >
              Completed ✓
            </base-button>
          </b-col>
        </template>
        <b-col cols="auto" class="px-0 d-none d-sm-block">
          <span align="center">
            <base-button
              link
              role="tab"
              v-b-toggle="'accordion-' + session.session"
              style="background-color: white; box-shadow: none; border: none;"
            >
              <i class="fas fa-chevron-down"></i>
            </base-button>
          </span>
        </b-col>
      </b-row>
      <b-collapse
        v-if="sessionClient"
        class="border-top m-3"
        :id="'accordion-' + session.session"
        :accordion="'accordion-' + session.session"
        role="tabpanel"
        aria-expanded="false"
      >
        <b-row class="mt-3">
          <b-col sm="3" v-if="!hideActions">
            <template v-if="isUnpaid">
              <b-link @click="$emit('edit', session.session)">
                <i class="fas fa-edit" style="width: 18px; height: 18px" />
                Edit Session</b-link
              ><br />
            </template>
            <template v-if="!isMissed && !isCanceled && !isPendingPayment">
              <b-link @click="$emit('missed', session.session)">
                <i
                  class="fas fa-calendar-minus"
                  style="width: 18px; height: 18px"
                />
                Mark as Missed</b-link
              ><br />
            </template>
            <template v-if="isUnpaid">
              <b-link @click="$emit('paid', session.session)">
                <i
                  class="fas fa-comment-dollar"
                  style="width: 18px; height: 18px"
                />
                Mark as Paid</b-link
              ><br />
            </template>
            <hr class="my-2" />
            <template v-if="isCompleted || isMissed">
              <b-link @click="handleMarkActive(session.session)">
                <i class="fas fa-undo" style="width: 18px; height: 18px" />
                Undo {{ isMissed ? 'Missed' : 'Complete' }}</b-link
              ><br />
            </template>
            <template
              v-if="
                !isUnpaid &&
                  !(session.meta && session.meta.stripe_payment_intent)
              "
            >
              <b-link @click="handleMarkUnpaid(session.session)">
                <i class="fas fa-undo" style="width: 18px; height: 18px" />
                Undo {{ isNoCharge ? 'No Charge' : 'Paid' }}</b-link
              ><br />
            </template>
            <template v-if="isUnpaid">
              <b-link @click="handleDelete(session.session)">
                <i class="far fa-trash-alt" style="width: 18px; height: 18px" />
                Delete Session</b-link
              ><br /><br />&nbsp;
            </template>
          </b-col>
          <b-col>
            <template v-if="!hideDetails">
              <b>Email: </b>
              {{ clientEmail }}
              <br />
              <b>Phone: </b>
              {{ clientPhone }}
              <br />
              <b>Links:</b>
              <template v-if="clientLink1 || clientLink2">
                <a :href="clientLink1" target="blank">
                  {{ clientLink1 }}
                </a>
                <a :href="clientLink2" target="blank">
                  {{ clientLink2 }}
                </a>
              </template>
              <template v-else>
                n/a
              </template>
              <br /><br />
            </template>
            <b>Status:</b>
            {{ session.status }}<br />
            <b>Payment:</b>
            {{ rateAmount }}
            {{
              session.payment_status === 'paid'
                ? 'Paid'
                : session.payment_status === 'unpaid'
                ? 'Unpaid'
                : session.payment_status === 'pending'
                ? 'Pending Payment'
                : session.payment_status === 'no-charge'
                ? 'No Charge'
                : session.payment_status
            }}
            <span v-if="isPaid" class="text-green">✓</span>
            <span v-if="isUnpaid" class="text-red">✖</span>
            <template
              v-if="session.meta && session.meta.stripe_payment_method_brand"
            >
              <b-img
                class="ml-3"
                height="15px"
                :src="
                  `/img/icons/cards/${session.meta.stripe_payment_method_brand}.png`
                "
              />
              {{ session.meta.stripe_payment_method_last4 }}
            </template>
            <br />
            <b>Session Notes</b>
            <b-badge class="ml-1" style="background-color: #ffe07c"
              >Private</b-badge
            ><br />
            <div class="text-sm mt-2" style="white-space: pre-line;">
              <template v-if="!editNotes">
                <template v-if="session.notes">
                  {{ session.notes }}
                </template>
                <template v-else>
                  <i>
                    (eg. session agenda, lesson plans, ...)
                  </i>
                </template>
                <b-link @click="editNotes = true"
                  ><i class="fas fa-pen fa-xs text-gray"
                /></b-link>
              </template>
              <base-input v-if="editNotes">
                <textarea
                  rows="3"
                  placeholder="Notes about session..."
                  class="form-control text-sm beige-text"
                  v-model="session.notes"
                  @keyup.esc="handleInlineEdit('editNotes')"
                  @blur="handleInlineEdit('editNotes')"
                />
              </base-input>
              <base-button
                size="sm"
                type="primary"
                @click="handleInlineEdit('editNotes')"
                v-if="editNotes"
                >Save</base-button
              >
              <!-- <b-button
                v-if="editNotes"
                variant="primary"
                size="sm"
                :to="`/clients/${session.client}`"
              >
                Add Client Task
              </b-button> -->
            </div>
            <!-- <b>Notes for Client</b><br />
            <textarea
              class="form-control black-text"
              id="clientNotes"
              v-model="session.client_notes"
              rows="10"
              cols="80"
            >
            </textarea>
            <div style="text-align: right; margin-top: 20px;">
              <p class="font-weight-normal" style="font-size: 14px;">
                Last emailed on 05/10/21
              </p>
            </div>
            <base-button
              type="primary"
              size="md"
              class="float-right"
              @click="$emit('email', session.session)"
            >
              Email Notes
            </base-button> -->
          </b-col>
        </b-row>
      </b-collapse>
    </b-card-body>
  </card>
</template>

<script>
import swal from 'sweetalert2'
import { DropdownMenu, DropdownItem, Dropdown } from 'element-ui'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'
import sessionDetailsMixin from '@/mixins/sessionDetailsMixin'
import notifications from '@/services/notifications'
import UserAvatar from '@/views/Layout/UserAvatar.vue'

export default {
  props: {
    session: Object,
    grouped: Boolean,
    hideAvatars: Boolean,
    hideNames: Boolean,
    hideActions: Boolean,
    hideDetails: Boolean
  },
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    UserAvatar
  },
  mixins: [providerDetailsMixin, sessionDetailsMixin],
  data() {
    return {
      editNotes: false
    }
  },
  computed: {
    sessionId() {
      return this.session.session
    },
    isCompleted() {
      return this.session.status === 'completed'
    },
    isMissed() {
      return this.session.status === 'missed'
    },
    isCanceled() {
      return this.session.status === 'canceled'
    },
    isScheduled() {
      return this.session.status === 'scheduled'
    },
    isPaid() {
      return this.session.payment_status === 'paid'
    },
    isUnpaid() {
      return this.session.payment_status === 'unpaid'
    },
    isPendingPayment() {
      return this.session.payment_status === 'pending'
    },
    isNoCharge() {
      return this.session.payment_status === 'no-charge'
    }
  },
  created() {
    this.$store.dispatch('provider/fetch')
    this.$store.dispatch('clients/fetch') // load first to associate clientDetails
    this.$store.dispatch('sessiongroups/fetch')
    this.$store.dispatch('sessions/fetch')
  },
  methods: {
    async handleInlineEdit(flag) {
      try {
        await this.$store.dispatch('sessions/save', this.session)
        this[flag] = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleMarkUnpaid(sessionId) {
      const result = await swal.fire({
        title: 'Are you sure?',
        html: `This will mark ${this.clientName}'s session for ${this.sessionDate} ${this.sessionStartTime} - ${this.sessionEndTime} as unpaid again.<br /><br />This should only be used if accidentally mark as paid.`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-warning btn-fill',
          cancelButton: 'btn btn-secondary btn-fill'
        },
        confirmButtonText: 'Yes, mark as unpaid',
        buttonsStyling: false,
        icon: 'warning'
      })
      if (result.value) {
        try {
          await this.$store.dispatch('sessions/updatePaymentStatus', {
            id: sessionId,
            paymentStatus: 'unpaid'
          })
        } catch (e) {
          this.$notify(notifications.defaultWarning(e))
        }
      }
    },
    async handleMarkActive(sessionId) {
      const result = await swal.fire({
        title: 'Are you sure?',
        html: `This will mark ${this.clientName}'s session for ${this.sessionDate} ${this.sessionStartTime} - ${this.sessionEndTime} as active again.<br /><br />This should only be used if accidentally marked as completed.`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-warning btn-fill',
          cancelButton: 'btn btn-secondary btn-fill'
        },
        confirmButtonText: 'Yes, mark as active',
        buttonsStyling: false,
        icon: 'warning'
      })
      if (result.value) {
        try {
          await this.$store.dispatch('sessions/updateStatus', {
            id: sessionId,
            status: 'scheduled'
          })
        } catch (e) {
          this.$notify(notifications.defaultWarning(e))
        }
      }
    },
    async handleDelete(sessionId) {
      const result = await swal.fire({
        title: 'Are you sure?',
        html: `This will delete ${this.clientName}'s session for ${this.sessionDate} ${this.sessionStartTime} - ${this.sessionEndTime}.<br /><br />Delete should only be used if accidentally created or can't be rescheduled. Use 'Mark as Missed' for missed or canceled sessions (even no charge sessions).`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger btn-fill',
          cancelButton: 'btn btn-secondary btn-fill'
        },
        confirmButtonText: 'Yes, delete it',
        buttonsStyling: false,
        icon: 'warning'
      })
      if (result.value) {
        try {
          await this.$store.dispatch('sessions/delete', sessionId)
          // this.$emit('searchQuery', '')
        } catch (e) {
          this.$notify(notifications.defaultWarning(e))
        }
      }
    }
  }
}
</script>
