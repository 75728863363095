<template>
  <modal
    :show.sync="showModal"
    size="sm"
    body-classes="p-0"
    hide-footer
    prevent-close
    hide-close
  >
    <h6 slot="header" class="modal-title">You're almost done...</h6>
    <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-5"
      class="border-0 mb-0"
    >
      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="handleSubmit(save)">
          <b-row>
            <b-col>
              <base-input
                name="First name"
                placeholder="First name"
                rules="required"
                v-model="fields.first_name"
              />
            </b-col>
            <b-col>
              <base-input
                name="Last name"
                placeholder="Last name"
                v-model="fields.last_name"
              />
            </b-col>
          </b-row>
          <base-input :rules="{ required: { allowFalse: false } }" name="Terms">
            <b-form-checkbox v-model="fields.agree">
              Agree to <a href="/terms" target="_blank">Terms of Service</a>
            </b-form-checkbox>
          </base-input>
          <div class="text-center">
            <base-button type="primary" native-type="submit">
              Complete Signup
            </base-button>
          </div>
        </form>
      </validation-observer>
    </card>
  </modal>
</template>

<script>
import notifications from '@/services/notifications'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'

export default {
  mixins: [providerDetailsMixin],
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    fields() {
      return { ...this.providerDetails } // clone
    }
  },
  created() {
    try {
      this.$store.dispatch('provider/fetch')
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  mounted() {
    if (this.$auth.dataLoaded && this.providerDetails.provider) {
      // nav to page
      this.setFields(this.providerDetails)
    } else {
      // first load
      this.$watch('$auth.dataLoaded', val => {
        if (val && this.$auth.user.data) {
          this.setFields(this.$auth.user.data)
        }
      })
    }
    if (this.providerReady) {
      // nav to page
      this.detectTimezone()
    } else {
      // first load
      this.$watch('providerReady', () => {
        this.detectTimezone()
      })
    }
  },
  methods: {
    setFields(data) {
      if (!data.agree) {
        this.fields.first_name =
          data.first_name !== data.email ? data.first_name : ''
        this.fields.last_name = data.last_name
        this.showModal = true
      }
    },
    detectTimezone() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (
        !this.providerDetails.timezone ||
        this.providerDetails.timezone !== timezone
      ) {
        this.providerDetails.timezone = timezone
        this.handleInlineEdit()
      }
    },
    async handleInlineEdit(flag) {
      try {
        await this.$store.dispatch('provider/save', this.providerDetails)
        this[flag] = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async save() {
      try {
        await this.$store.dispatch('provider/save', this.fields)
        this.showModal = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>
