<template>
  <div>
    <base-header class="pb-6" />
    <b-container fluid class="mt--6">
      <card
        class="no-border-card"
        body-classes="px-0 pb-0"
        footer-classes="pb-0"
      >
        <template slot="header">
          <h1 class="mb-0 d-inline-block">Clients</h1>
          <base-button icon type="primary" class="ml-5 mb-2" @click="handleAdd">
            <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
            <span class="btn-inner--text">Add New</span>
          </base-button>
        </template>
        <!-- Web search bar -->
        <b-col
          cols="12"
          class="d-none d-sm-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <base-input
            v-model="searchQuery"
            prepend-icon="fas fa-search"
            placeholder="Search..."
          >
          </base-input>
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item + ' per page'"
              :value="item"
            >
            </el-option>
          </el-select>
        </b-col>
        <!-- Mobile search bar - more margin @ bottom -->
        <b-col
          cols="12"
          class="mb-4 d-flex d-sm-none justify-content-center justify-content-sm-between flex-wrap"
        >
          <base-input
            v-model="searchQuery"
            prepend-icon="fas fa-search"
            placeholder="Search..."
          >
          </base-input>
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item + ' per page'"
              :value="item"
            >
            </el-option>
          </el-select>
        </b-col>
        <el-table
          :data="queriedData"
          class="d-block"
          style="width: 100%"
          row-key="client"
          header-row-class-name="thead-light"
          cell-class-name="text-sm"
          @sort-change="sortChange"
          @selection-change="selectionChange"
        >
          <el-table-column
            prop="first_name"
            min-width="230"
            sortable
            label="Name"
          >
            <template v-slot="{ row }">
              <router-link :to="`/clients/${row.client}`"
                ><user-avatar
                  class="d-none d-sm-inline-block"
                  :img="row.img"
                  :name="`${row.first_name} ${row.last_name}`"
                  :email="row.email"
                  size="md"
                  style="margin-right: 10px"
                />
                {{ row.first_name }} {{ row.last_name }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column min-width="230" prop="email" label="Email" sortable>
            <template v-slot="{ row }"
              ><a :href="`mailto:${row.email}`"
                ><i
                  class="far fa-envelope pr-2 text-black"
                  :href="`mailto:${row.email}`"
                ></i
              ></a>
              {{ row.email }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" prop="phone" label="Phone" sortable>
            <template v-slot="{ row }">
              {{ formatPhone(row.phone) }}
            </template>
          </el-table-column>
          <el-table-column min-width="130" label="Revenue" sortable>
            <template v-slot="{ row }">
              {{ statRevenue(row.client).replace('$0', '-') }}
            </template>
          </el-table-column>
          <el-table-column min-width="130" label="Unpaid" sortable>
            <template v-slot="{ row }">
              {{ statUnpaid(row.client).replace('$0', '-') }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" sortable label="Status">
            <template v-slot="{ row }">
              <b-link @click="toggleStatus(row)">
                <span
                  :class="
                    `text-${row.status !== 'inactive' ? 'success' : 'danger'}`
                  "
                  >●
                </span>
                {{ row.status || 'active' }}
              </b-link>
            </template>
          </el-table-column>
          <el-table-column
            min-width="55px"
            align="center"
            label="Actions"
            v-if="false"
          >
            <div
              slot-scope="{ row }"
              class="d-flex"
              style="margin-left: 10px; margin-top: 10px"
            >
              <el-dropdown trigger="click" class="dropdown" align="center">
                <span
                  class="btn btn-sm btn-icon-only text-light"
                  align="center"
                >
                  <i class="fas fa-ellipsis-v mt-2"></i>
                </span>
                <el-dropdown-menu class="dropdown-menu show" slot="dropdown">
                  <b-dropdown-item @click="handleEdit(row)">
                    <i class="fas fa-edit" style="vertical-align: middle"></i
                    >Edit
                  </b-dropdown-item>

                  <b-dropdown-item @click="handleDelete(row)">
                    <i
                      class="far fa-trash-alt"
                      style="vertical-align: middle"
                    ></i>
                    Delete
                  </b-dropdown-item>
                  <b-dropdown-item @click="handleNew(row)">
                    <i
                      class="far fa-calendar-plus"
                      style="vertical-align: middle"
                    ></i>
                    Add New Session
                  </b-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-table-column>
        </el-table>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <p class="card-category">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} clients

            <span v-if="selectedRows.length">
              &nbsp; &nbsp; {{ selectedRows.length }} rows selected
            </span>
          </p>
          <base-pagination
            class="pagination-no-border"
            :current="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
            @change="paginationChanged($event)"
          >
          </base-pagination>
        </div>
      </card>
    </b-container>

    <ClientAddEditModal :mode="addEditMode" :clientId.sync="addEditId" />
  </div>
</template>

<script>
import swal from 'sweetalert2'
import phoneFormatter from 'phone-formatter'
import notifications from '@/services/notifications'
import searchMixin from '@/mixins/searchMixin'
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown
} from 'element-ui'
import ClientAddEditModal from '../Modals/ClientAddEditModal.vue'
import UserAvatar from '@/views/Layout/UserAvatar.vue'

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ClientAddEditModal,
    UserAvatar
  },
  mixins: [searchMixin],
  data() {
    return {
      addEditMode: null,
      addEditId: null,
      propsToSearch: ['full_name', 'email', 'phone']
    }
  },
  computed: {
    clientsReady() {
      return this.$store.state.clients.ready
    },
    sourceData() {
      let collection = JSON.parse(
        JSON.stringify(this.$store.getters['clients/clientCollection'])
      )
      collection.forEach(client => {
        client.full_name =
          (client.first_name || '') + ' ' + (client.last_name || '')
      })
      return collection
    }
  },
  created() {
    try {
      this.$store.dispatch('sessions/fetch')
      this.$store.dispatch('clients/fetch')
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  mounted() {
    if (this.clientsReady) {
      // nav to page
      this.initTableData()
    } else {
      // first load
      this.$watch('clientsReady', () => {
        this.initTableData()
      })
    }
  },
  methods: {
    formatPhone(phone) {
      return phone && phone.length === 10
        ? phoneFormatter.format(phone, '(NNN) NNN-NNNN')
        : phone
    },
    sessions(clientId) {
      return this.$store.state.sessions.sessions.filter(
        session => session.client === clientId
      )
    },
    doneSessions(clientId) {
      return this.$store.getters['sessions/doneSessions'].filter(
        session => session.client === clientId
      )
    },
    async toggleStatus(row) {
      row.status = row.status !== 'inactive' ? 'inactive' : 'active'
      delete row.full_name // remove added search fields
      try {
        await this.$store.dispatch('clients/save', row)
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    statRevenue(clientId) {
      return this.sessions(clientId)
        .filter(session => session.payment_status === 'paid')
        .reduce((sum, session) => sum + session.rate_amount, 0)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        })
    },
    statUnpaid(clientId) {
      return this.sessions(clientId)
        .filter(
          session =>
            session.payment_status === 'unpaid' ||
            session.payment_status === 'pending'
        )
        .reduce((sum, session) => sum + session.rate_amount, 0)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        })
    },
    handleAdd() {
      this.addEditMode = 'add'
      this.addEditId = '__NEW__'
    },
    handleEdit(row) {
      try {
        this.$store.dispatch('clients/fetchClientDetails', row.client)
        this.addEditMode = 'edit'
        this.addEditId = row.client
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleDelete(row) {
      const result = await swal.fire({
        title: 'Are you sure?',
        html: `This will delete ${row.first_name} ${row.last_name}.<br /><br />Note: Deleting a client won't delete their upcoming sessions.`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger btn-fill',
          cancelButton: 'btn btn-secondary btn-fill'
        },
        confirmButtonText: 'Yes, delete it',
        buttonsStyling: false,
        icon: 'warning'
      })
      if (result.value) {
        try {
          await this.$store.dispatch('clients/delete', row.client)
          this.searchQuery = ''
        } catch (e) {
          this.$notify(notifications.defaultWarning(e))
        }
      }
    }
  }
}
</script>
