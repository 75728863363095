<template>
  <b-card
    no-body
    img-src="/img/theme/img-1-1000x600.jpg"
    alt="Image placeholder"
    img-top
    class="card-profile"
  >
    <div
      class="card-img-overlay d-flex text-right align-items-right mt--3 ml--3"
    >
      <div>
        <b-card-text class="bg-translucent-primary px-2">
          <b-link @click="toggleStatus">
            <span
              :class="
                `text-${
                  clientDetails.status === 'active' ? 'success' : 'danger'
                }`
              "
              >●
            </span>
            <span class="text-white font-weight-400">
              {{ clientDetails.status }}
            </span>
          </b-link>
        </b-card-text>
      </div>
    </div>
    <div class="row justify-content-center mt--5">
      <user-avatar
        :img="clientDetails.img"
        :name="clientName"
        :email="clientEmail"
        size="100px"
        style="border: 3px solid #fff"
      />
    </div>
    <b-card-header class="text-center border-0 p-lg-3 pb-lg-0">
      <div class="d-flex justify-content-between mt--5">
        <b-button
          v-if="clientEmail"
          variant="info"
          size="sm"
          :href="`mailto:${clientEmail}`"
          >Email</b-button
        ><b-button
          v-if="clientDetails.phone"
          variant="default"
          size="sm"
          :href="`tel:${clientDetails.phone}`"
          >Call</b-button
        >
      </div>
    </b-card-header>
    <b-row>
      <b-col>
        <div
          class="card-profile-stats d-flex justify-content-center py-0 mt--4"
        >
          <div>
            <span class="heading">
              <router-link
                :to="{
                  path: '/sessions',
                  query: { client: clientDetails.client, tab: 'active' }
                }"
              >
                {{ activeSessions.length }}
              </router-link>
            </span>
            <span class="description">Active</span>
          </div>
          <div>
            <span class="heading">
              <router-link
                :to="{
                  path: '/sessions',
                  query: { client: clientDetails.client, tab: 'done' }
                }"
              >
                {{ doneSessions.length }}
              </router-link>
            </span>
            <span class="description">Done</span>
          </div>
          <div>
            <span class="heading">
              <router-link
                :to="{
                  path: '/sessions',
                  query: { client: clientDetails.client, tab: 'unpaid' }
                }"
              >
                {{ unpaidSessions.length }}
              </router-link>
            </span>
            <span class="description">Unpaid</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-card-body class="pt-2">
      <b-row>
        <b-col class="d-flex justify-content-center">
          <h1 class="display-4 lh-120 mb-0 text-center">
            {{ clientName }}
          </h1>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col class="text-center mt-1">
          <h4 class="font-weight-light" v-if="!editLevel">
            {{ clientDetails.level || 'Add level...' }}
            <b-link @click="editLevel = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </h4>
          <base-input
            placeholder="6th Grade, Dieting, ..."
            input-classes="form-control-sm text-sm"
            v-model="clientDetails.level"
            v-if="editLevel"
            :autofocus="editLevel"
            @keyup.enter="handleInlineEdit('editLevel')"
            @blur="handleInlineEdit('editLevel')"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <h4
            class="font-weight-light"
            style="white-space: pre-line;"
            v-if="!editLocation"
          >
            {{ clientLocation || 'No location set' }}
            <b-link @click="handleClickEditLocation"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </h4>
          <vue-google-autocomplete
            ref="location"
            id="map"
            placeholder="Start typing address..."
            classname="form-control form-control-sm text-sm"
            :country="['us', 'ca']"
            v-on:placechanged="handleEditLocationData"
            v-if="editLocation"
            @keyup.enter="handleEditLocation"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <h5 v-if="clientDetails.link_1 && !editLink1">
            <a :href="clientDetails.link_1" target="blank">
              Open Link 1
            </a>
            <b-link @click="editLink1 = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </h5>
          <h5 v-else-if="!editLink1">
            Add a link...
            <b-link @click="editLink1 = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </h5>
          <base-input
            placeholder="https://drive.google.com/..."
            input-classes="form-control-sm text-xs"
            v-model="clientDetails.link_1"
            v-if="editLink1"
            :autofocus="editLink1"
            @keyup.enter="handleInlineEdit('editLink1')"
            @blur="handleInlineEdit('editLink1')"
          />
        </b-col>
      </b-row>
      <b-row v-if="clientDetails.link_1 || clientDetails.link_2">
        <b-col class="text-center">
          <h5 v-if="clientDetails.link_2 && !editLink2">
            <a :href="clientDetails.link_2" target="blank">
              Open Link 2
            </a>
            <b-link @click="editLink2 = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </h5>
          <h5 v-else-if="!editLink2">
            Add another link...
            <b-link @click="editLink2 = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </h5>
          <base-input
            placeholder="https://classroom.google.com/..."
            input-classes="form-control-sm text-xs"
            v-model="clientDetails.link_2"
            v-if="editLink2"
            :autofocus="editLink2"
            @keyup.enter="handleInlineEdit('editLink2')"
            @blur="handleInlineEdit('editLink2')"
          />
        </b-col>
      </b-row>
      <b-row v-if="featureFlag.stripe_payments && providerPayment">
        <b-col class="text-center">
          <h5>
            Zenclear Payments<br />
            <span class="font-weight-light">
              <template v-if="clientPayment">
                On file
                <b-img
                  class="ml-1"
                  height="15px"
                  :src="
                    `/img/icons/cards/${clientDetails.meta.stripe_payment_method_brand}.png`
                  "
                />
                {{ clientDetails.meta.stripe_payment_method_last4 }}
              </template>
              <template v-else>
                No card on file
              </template>
              <br />
              <b-link @click="sendClientCardEmail"
                >Ask for new payment method</b-link
              >
            </span>
          </h5>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import swal from 'sweetalert2'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import notifications from '@/services/notifications'
import clientDetailsMixin from '@/mixins/clientDetailsMixin'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'
import UserAvatar from '@/views/Layout/UserAvatar.vue'
import apiService from '../../../services/apiService'

export default {
  props: ['clientId'],
  components: {
    UserAvatar,
    VueGoogleAutocomplete
  },
  mixins: [clientDetailsMixin, providerDetailsMixin],
  data() {
    return {
      editLocation: false,
      editLevel: false,
      editLink1: false,
      editLink2: false
    }
  },
  created() {
    try {
      this.$store.dispatch('provider/fetch')
      this.$store.dispatch('clients/fetchClientDetails', this.clientId)
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  methods: {
    async handleInlineEdit(flag) {
      try {
        await this.$store.dispatch('clients/save', this.clientDetails)
        this[flag] = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async toggleStatus() {
      this.clientDetails.status =
        this.clientDetails.status === 'active' ? 'inactive' : 'active'
      try {
        await this.$store.dispatch('clients/save', this.clientDetails)
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleEditLocation() {
      // user typed in location, no location fields :(
      this.clientDetails.location = {
        location: this.$refs.location.autocompleteText
      }
      try {
        await this.$store.dispatch('clients/save', this.clientDetails)
        this.editLocation = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleEditLocationData(address) {
      // google autocomplete location...eg:
      // {
      //     "street_number": "1",
      //     "route": "Infinite Loop",
      //     "locality": "Cupertino",
      //     "administrative_area_level_2": "Santa Clara County",
      //     "administrative_area_level_1": "CA",
      //     "country": "United States",
      //     "postal_code": "95014",
      //     "latitude": 37.3318641,
      //     "longitude": -122.0302537
      // }
      this.clientDetails.location = {
        address: `${address.street_number} ${address.route}`,
        city: address.locality,
        state: address.administrative_area_level_1,
        zip: address.postal_code,
        country:
          address.country === 'United States'
            ? 'US'
            : address.country === 'Canada'
            ? 'CA'
            : address.country
      }
      try {
        await this.$store.dispatch('clients/save', this.clientDetails)
        this.editLocation = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    handleClickEditLocation() {
      this.editLocation = true
      setImmediate(() => {
        this.$refs.location.update(this.clientLocation?.replace(/\n/g, ', '))
      })
    },
    async sendClientCardEmail() {
      const result = await swal.fire({
        title: 'Request new payment method',
        html: `This will send an email to ${this.clientName} asking for a new payment method. The payment method is securely stored with Stripe and will be used for all future session payments.`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary btn-fill',
          cancelButton: 'btn btn-secondary btn-fill'
        },
        confirmButtonText: 'Request',
        buttonsStyling: false,
        icon: 'question'
      })
      if (result.value) {
        try {
          await apiService.editEndpoint(
            'payment/sendclientcardemail',
            this.clientId
          )
          this.$notify(notifications.defaultSaveSuccess('Email sent'))
        } catch (e) {
          this.$notify(notifications.defaultWarning(e))
        }
      }
    }
  }
}
</script>
