<template>
  <card no-body header-classes="py-3">
    <b-row align-v="center" slot="header">
      <b-col>
        <h2 class="mb-0">
          Active Clients
          <div
            class="icon-md icon-shape bg-gradient-green text-white rounded-circle ml-2"
          >
            <i class="ni ni-single-02"></i>
          </div>
        </h2>
      </b-col>
      <b-col cols="4" class="text-right d-none d-sm-inline-block">
        <router-link to="/clients">
          All Clients &raquo;
        </router-link>
      </b-col>
      <b-col cols="4" class="text-right d-inline-block d-sm-none">
        <div class="text-sm">
          <router-link to="/clients">
            All Clients &raquo;
          </router-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div role="tablist" class="accordion">
          <b-card
            no-body
            class="mb-0"
            v-for="client in activeClients"
            :key="client.client"
          >
            <b-card-header
              header-tag="header"
              href="#"
              role="tab"
              class="py-3"
              v-b-toggle="'accordion-' + client.client"
            >
              <b-row align-v="center" class="mr-0">
                <b-col class="ml-2 mr--2" cols="auto">
                  <router-link :to="`/clients/${client.client}`">
                    <user-avatar
                      :img="client.img"
                      :name="client.first_name + ' ' + client.last_name"
                      :email="client.email"
                    />
                  </router-link>
                </b-col>
                <b-col class="ml-1">
                  <span class="font-weight-600">
                    <router-link :to="`/clients/${client.client}`">
                      {{ client.first_name + ' ' + client.last_name }}
                    </router-link>
                  </span>
                  <p v-if="client.level" class="mb-0">
                    {{ client.level }}
                  </p>
                </b-col>
              </b-row>
            </b-card-header>
            <b-collapse
              :id="'accordion-' + client.client"
              accordion="my-accordion"
              role="tabpanel"
            >
              <card class="mb-0" body-classes="py-1">
                <Tasks showIncomplete :clientId="client.client" />
              </card>
            </b-collapse>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!activeClients.length">
      <b-col
        cols="12"
        class="d-flex justify-content-center flex-wrap text-sm py-4"
      >
        <router-link to="/clients">
          Create your first client
        </router-link>
      </b-col>
    </b-row>
  </card>
</template>

<script>
import UserAvatar from '@/views/Layout/UserAvatar.vue'
import Tasks from '../Shared/Tasks.vue'
import notifications from '@/services/notifications'

export default {
  components: {
    UserAvatar,
    Tasks
  },
  data() {
    return {}
  },
  computed: {
    activeClients() {
      return this.$store.getters['clients/activeClients']
    }
  },
  created() {
    try {
      this.$store.dispatch('clients/fetch')
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  }
}
</script>
