<template>
  <b-avatar
    :size="size || 'lg'"
    :text="initials"
    :src="gravatar"
    variant="secondary"
    onerror='this.style.display = "none"'
  />
</template>
<script>
import md5 from 'md5'

export default {
  props: {
    img: String,
    name: String,
    email: String,
    size: String
  },
  computed: {
    gravatar() {
      const hash = md5((this.email && this.email.trim().toLowerCase()) || '')
      return `https://www.gravatar.com/avatar/${hash}?s=200&d=${this.img ||
        '404'}`
    },
    initials() {
      if (this.name?.trim().length <= 2 || !~this.name?.indexOf(' '))
        return this.name

      return (
        this.name.split(' ')[0].substring(0, 1) +
        this.name.split(' ')[1].substring(0, 1)
      )
    }
  }
}
</script>
