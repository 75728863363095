import Vue from 'vue'
import phoneFormatter from 'phone-formatter'

export default {
  computed: {
    clientsReady() {
      return this.$store.state.clients.ready
    },
    clientDetails() {
      if (!this.clientId) {
        return {}
      }
      return this.$store.state.clients.clientDetails[this.clientId] || {}
    },
    clientName() {
      return this.clientDetails
        ? `${this.clientDetails.first_name || ''} ${this.clientDetails
            .last_name || ''}`
        : ''
    },
    clientEmail() {
      return this.clientDetails.email
    },
    clientPhone() {
      return this.clientDetails.phone && this.clientDetails.phone.length === 10
        ? phoneFormatter.format(this.clientDetails.phone, '(NNN) NNN-NNNN')
        : this.clientDetails.phone
    },
    clientLocation() {
      if (this.clientDetails.location?.location)
        return this.clientDetails.location?.location
      else
        return (
          (this.clientDetails.location?.address
            ? this.clientDetails.location?.address + '\n'
            : '') +
          ((this.clientDetails.location?.city &&
            this.clientDetails.location?.state &&
            this.clientDetails.location?.city +
              ', ' +
              this.clientDetails.location?.state) ||
            '')
        )
    },
    clientContacts: {
      get() {
        // if no contacts, return blank for form filler on clientprofilecontacts.vue
        if (
          !this.clientDetails.contacts ||
          !this.clientDetails.contacts.length
        ) {
          this.clientDetails.contacts = [{}]
        }
        return this.clientDetails.contacts
      },
      set(contacts) {
        Vue.set(this.clientDetails, 'contacts', contacts)
      }
    },
    clientRates: {
      get() {
        this.clientDetails.rates = this.clientDetails.rates || []
        return this.clientDetails.rates
      },
      set(rates) {
        Vue.set(this.clientDetails, 'rates', rates)
      }
    },
    clientPayment() {
      return (
        this.clientDetails.meta?.stripe_enabled &&
        this.clientDetails.meta?.stripe_customer &&
        this.clientDetails.meta?.stripe_payment_method &&
        this.clientDetails.meta?.stripe_payment_method_brand &&
        this.clientDetails.meta?.stripe_payment_method_last4
      )
    },
    sessions() {
      return this.$store.state.sessions.sessions.filter(
        session => session.client === this.clientId
      )
    },
    activeSessions() {
      return this.$store.getters['sessions/activeSessions'].filter(
        session => session.client === this.clientId
      )
    },
    doneSessions() {
      return this.$store.getters['sessions/doneSessions'].filter(
        session => session.client === this.clientId
      )
    },
    unpaidSessions() {
      return this.$store.getters['sessions/unpaidSessions'].filter(
        session => session.client === this.clientId
      )
    }
  }
}
