<template>
  <modal :show.sync="showModal" size="sm" hide-footer>
    <h6 slot="header" class="modal-title">Mark as Paid</h6>

    <div v-if="sessionId" class="text-center">
      <h1 class="pt-0">You got paid!</h1>
      <p>
        It seems you accepted an external payment<br />that is not integrated
        with our system.
      </p>

      <h3>{{ clientName }}</h3>
      <h4>{{ rateName }} ({{ rateDuration }}min)</h4>
      <p>Amount to be charged: {{ rateAmount }}</p>
      <base-button
        type="secondary"
        v-b-tooltip.hover.right.v-secondary
        title="You have accepted an external payment. Just mark as paid for accurate reports and stats."
        style="margin-right: 0; margin-bottom: 2px; width: 244.03px;"
        @click="markAsPaid('paid')"
      >
        Mark as paid
      </base-button>
      <br v-if="featureFlag.stripe_payments && !providerPayment" />
      <base-button
        type="secondary"
        v-b-tooltip.hover.right.v-secondary
        title="Have Zenclear take care of payments and handle billing issues."
        style="margin-right: 0; width: 244.03px;"
        @click="$router.push('/account/settings')"
        v-if="featureFlag.stripe_payments && !providerPayment"
      >
        Set up Zenclear Payments
      </base-button>
      <br />
      <base-button
        type="secondary"
        class="btn btn-link text-dark font-weight-normal mt-2"
        v-b-tooltip.hover.right.v-secondary
        title="We'll mark it as completed and no charge needed."
        style="background: none; border: none; font-size: 14px;"
        @click="markAsPaid('no-charge')"
      >
        No Charge
      </base-button>
    </div>
  </modal>
</template>

<script>
import notifications from '@/services/notifications'
import sessionModalMixin from '@/mixins/sessionModalMixin'
import sessionDetailsMixin from '@/mixins/sessionDetailsMixin'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'

export default {
  props: ['sessionId'],
  mixins: [sessionModalMixin, sessionDetailsMixin, providerDetailsMixin],
  methods: {
    async markAsPaid(paymentStatus) {
      try {
        await this.$store.dispatch('sessions/updatePaymentStatus', {
          id: this.sessionId,
          paymentStatus
        })
        this.showModal = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    showComplete() {
      this.showModal = false
      this.$parent.handleComplete(this.sessionId)
    }
  }
}
</script>
