<template>
  <modal :show.sync="showModal" size="sm" hide-footer>
    <h6 slot="header" class="modal-title">Mark as Canceled</h6>

    <div v-if="sessionId" class="text-center">
      <h1 class="pt-0">That sucks!</h1>
      <p v-if="sessionShowChargeLater">
        This will mark your session as canceled.
      </p>

      <h3>{{ clientName }}</h3>
      <h4>{{ rateName }} ({{ rateDuration }}min)</h4>
      <p v-if="sessionShowChargeLater">
        Amount to be charged: {{ rateAmount }}
      </p>
      <p v-if="sessionShowPrePaid">Amount Pre-Paid: {{ rateAmount }}</p>
      <p v-if="!sessionShowPrePaid">
        Depending on your policy, you can charge or<br />
        forgive (no charge) this canceled session.
      </p>
      <p
        v-if="
          featureFlag.stripe_payments && !clientPayment && !sessionShowPrePaid
        "
      >
        This is {{ clientName }}'s first paid session, a payment link will be
        sent to them via email.
      </p>
      <base-button
        type="primary"
        v-b-tooltip.hover.right.v-secondary
        title="We'll email out a payment link and securely use it for future sessions."
        style="margin-right: 0; margin-bottom: 2px; width: 244.03px;"
        @click="markAsCanceled(true)"
        v-if="providerPayment && !clientPayment && !sessionShowPrePaid"
      >
        Charge Now
      </base-button>
      <base-button
        type="primary"
        style="margin-right: 0; margin-bottom: 2px; width: 244.03px;"
        @click="markAsCanceled(true)"
        v-if="providerPayment && clientPayment && !sessionShowPrePaid"
      >
        Charge Now
      </base-button>
      <base-button
        type="primary"
        v-b-tooltip.hover.right.v-secondary
        title="Have Zenclear take care of payments and handle billing issues."
        style="margin-right: 0; margin-bottom: 2px; width: 244.03px;"
        @click="$router.push('/account/settings')"
        v-if="
          featureFlag.stripe_payments && !providerPayment && !sessionShowPrePaid
        "
      >
        Set up Zenclear Payments
      </base-button>
      <base-button
        type="primary"
        style="margin-right: 0; margin-bottom: 2px; width: 244.03px;"
        @click="markAsCanceled()"
        v-if="sessionShowPrePaid"
      >
        Mark as Canceled
      </base-button>
      <br />
      <base-button
        type="secondary"
        v-b-tooltip.hover.right.v-secondary
        title="No emails will be sent. We'll mark it as canceled on the Unpaid tab."
        style="margin-right: 0; width: 244.03px;"
        @click="markAsCanceled()"
        v-if="sessionShowChargeLater && !clientPayment"
      >
        Charge Later
      </base-button>
      <base-button
        type="secondary"
        v-b-tooltip.hover.right.v-secondary
        title="We'll mark it as canceled on the Unpaid tab."
        style="margin-right: 0; width: 244.03px;"
        @click="markAsCanceled()"
        v-if="sessionShowChargeLater && clientPayment"
      >
        Charge Later
      </base-button>
      <br v-if="sessionShowChargeLater" />
      <base-button
        type="secondary"
        class="btn btn-link text-dark font-weight-normal mt-2"
        v-b-tooltip.hover.right.v-secondary
        title="No emails will be sent. We'll mark it as canceled and no charge needed."
        style="background: none; border: none; font-size: 14px;"
        @click="skipCharge()"
        v-if="!clientPayment && !sessionShowPrePaid"
      >
        No Charge
      </base-button>
      <base-button
        type="secondary"
        class="btn btn-link text-dark font-weight-normal mt-2"
        v-b-tooltip.hover.right.v-secondary
        title="We'll mark it as canceled and no charge needed."
        style="background: none; border: none; font-size: 14px;"
        @click="skipCharge()"
        v-if="clientPayment && !sessionShowPrePaid"
      >
        No Charge
      </base-button>
    </div>
  </modal>
</template>

<script>
import notifications from '@/services/notifications'
import sessionModalMixin from '@/mixins/sessionModalMixin'
import sessionDetailsMixin from '@/mixins/sessionDetailsMixin'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'

export default {
  props: ['sessionId'],
  mixins: [sessionModalMixin, sessionDetailsMixin, providerDetailsMixin],
  methods: {
    async markAsCanceled(charge) {
      try {
        if (charge) {
          await this.$store.dispatch('sessions/chargeSession', {
            id: this.sessionId
          })
        }
        await this.$store.dispatch('sessions/updateStatus', {
          id: this.sessionId,
          status: 'canceled'
        })
        this.showModal = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async skipCharge() {
      try {
        await this.$store.dispatch('sessions/updateStatus', {
          id: this.sessionId,
          status: 'canceled',
          noCharge: true
        })
        this.showModal = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>
