<template>
  <b-row>
    <b-col md="6">
      <stats-card
        title="Revenue"
        type="gradient-orange"
        :sub-title="statRevenue"
        icon="ni ni-chart-pie-35"
      >
        <template slot="footer">
          <span class="text-nowrap" v-if="clientId">Client lifetime value</span>
          <span class="text-nowrap" v-else
            >This month: {{ statTotalRevenueThisMonth }}</span
          >
        </template>
      </stats-card>
      <stats-card
        title="Payments"
        type="gradient-green"
        :sub-title="statPaymentsUnpaid"
        text="unpaid"
        icon="ni ni-money-coins"
      >
        <template slot="footer">
          <span class="text-nowrap"> Total unpaid: {{ statTotalUnpaid }} </span>
        </template>
      </stats-card>
    </b-col>
    <b-col md="6">
      <stats-card
        title="This Month"
        type="gradient-info"
        :sub-title="statSessionsThisMonth"
        :text="statSessionsThisMonth === '1' ? 'session' : 'sessions'"
        icon="ni ni-chart-bar-32"
      >
        <template slot="footer">
          <span class="text-nowrap">
            {{ statSessionsLastMonth }}
            {{ statSessionsLastMonth === '1' ? 'session' : 'sessions' }}
            last month
          </span>
        </template>
      </stats-card>
      <stats-card
        title="This Month"
        type="gradient-red"
        :sub-title="statHoursThisMonth"
        :text="statHoursThisMonth === '1' ? 'hour' : 'hours'"
        icon="ni ni-active-40"
      >
        <template slot="footer">
          <span class="text-nowrap">
            {{ statHoursLastMonth }}
            {{ statHoursLastMonth === '1' ? 'hour' : 'hours' }} last month
          </span>
        </template>
      </stats-card>
    </b-col>
  </b-row>
</template>
<script>
import moment from 'moment'
import notifications from '@/services/notifications'

export default {
  props: ['clientId'],
  data() {
    return {}
  },
  computed: {
    statRevenue() {
      return this.sessions
        .filter(session => session.payment_status === 'paid')
        .reduce((sum, session) => sum + session.rate_amount, 0)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        })
    },
    statPaymentsPaid() {
      return this.sessions
        .filter(session => session.payment_status === 'paid')
        .length.toString()
    },
    statTotalRevenueThisMonth() {
      let thisMonth = new Date().getMonth()
      let thisYear = new Date().getYear()
      const total = this.sessions
        .filter(
          session =>
            new Date(session.start_date_time).getMonth() === thisMonth &&
            new Date(session.start_date_time).getYear() === thisYear &&
            session.payment_status === 'paid'
        )
        .reduce((sum, session) => sum + session.rate_amount, 0)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        })
      return total
    },
    statTotalUnpaid() {
      return this.sessions
        .filter(session => session.payment_status === 'unpaid')
        .reduce((sum, session) => sum + session.rate_amount, 0)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0
        })
    },
    statPaymentsUnpaid() {
      return this.sessions
        .filter(session => session.payment_status === 'unpaid')
        .length.toString()
    },
    statSessionsThisMonth() {
      let thisMonth = new Date().getMonth()
      let thisYear = new Date().getYear()
      return this.sessions
        .filter(
          session =>
            new Date(session.start_date_time).getMonth() === thisMonth &&
            new Date(session.start_date_time).getYear() === thisYear &&
            (session.status === 'scheduled' || session.status === 'completed')
        )
        .length.toString()
    },
    statSessionsLastMonth() {
      let lastMonth = moment()
        .subtract(1, 'months')
        .month()
      let yearOfLastMonth = moment()
        .subtract(1, 'months')
        .year()
      return this.sessions
        .filter(
          session =>
            new Date(session.start_date_time).getMonth() === lastMonth &&
            new Date(session.start_date_time).getYear() === yearOfLastMonth &&
            (session.status === 'scheduled' || session.status === 'completed')
        )
        .length.toString()
    },
    statHoursThisMonth() {
      let thisMonth = new Date().getMonth()
      let thisYear = new Date().getYear()
      const sessionHoursThisMonth = this.sessions
        .filter(
          session =>
            new Date(session.start_date_time).getMonth() === thisMonth &&
            new Date(session.start_date_time).getYear() === thisYear &&
            (session.status === 'scheduled' || session.status === 'completed')
        )
        .reduce(
          (sum, session) =>
            sum + Math.round((session.rate_duration / 60) * 100) / 100,
          0
        )
        .toFixed(2)
        .toString()
        .replace('.00', '')
      return sessionHoursThisMonth.toString()
    },
    statHoursLastMonth() {
      let lastMonth = moment()
        .subtract(1, 'months')
        .month()
      let yearOfLastMonth = moment()
        .subtract(1, 'months')
        .year()
      const sessionHoursLastMonth = this.sessions
        .filter(
          session =>
            new Date(session.start_date_time).getMonth() === lastMonth &&
            new Date(session.start_date_time).getYear() === yearOfLastMonth &&
            (session.status === 'scheduled' || session.status === 'completed')
        )
        .reduce(
          (sum, session) =>
            sum + Math.round((session.rate_duration / 60) * 100) / 100,
          0
        )
        .toFixed(2)
        .toString()
        .replace('.00', '')
      return sessionHoursLastMonth.toString()
    },
    sessions() {
      if (this.clientId) {
        return this.$store.state.sessions.sessions.filter(
          session => session.client === this.clientId
        )
      } else {
        return this.$store.state.sessions.sessions
      }
    },
    doneSessions() {
      if (this.clientId) {
        return this.$store.getters['sessions/doneSessions'].filter(
          session => session.client === this.clientId
        )
      } else {
        return this.$store.getters['sessions/doneSessions']
      }
    }
  },
  created() {
    try {
      this.$store.dispatch('sessions/fetch')
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  }
}
</script>
