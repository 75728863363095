<template>
  <card>
    <b-row align-v="center" slot="header">
      <b-col>
        <div class="justify-content-between d-flex">
          <h3 class="mb-0">Zenclear Payments</h3>
          <base-button
            icon
            type="primary"
            class="btn btn-md"
            size="sm"
            v-b-tooltip.hover.left
            title="Coming soon!"
            v-if="!featureFlag.stripe_payments && !setupMode"
            >Set up
          </base-button>
          <base-button
            icon
            type="primary"
            class="btn btn-md"
            size="sm"
            v-if="featureFlag.stripe_payments && !setupMode"
            @click="setupBilling = true"
            >Set up
          </base-button>
        </div>
      </b-col>
    </b-row>
    <template v-if="!setupMode">
      <b-row>
        <b-col>
          <p>
            Currently not set-up for online payments
          </p>
          <h4>Why should I set up automated online payments?</h4>
          <p>
            Businesses accepting online payments make
            <b>2X more</b> than those who don't. Spend less time chasing down
            payments.
          </p>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-row>
        <b-col>
          <h6 class="heading-small text-muted mb-0">Currency</h6>
          <div class="text-sm mb-3">
            <template v-if="!editCurrency">
              {{
                (providerDetails.billing &&
                  providerDetails.billing.currency &&
                  providerDetails.billing.currency.toUpperCase()) ||
                  'not specified'
              }}
              <b-link @click="editCurrency = true"
                ><i class="fas fa-pen fa-xs text-gray"
              /></b-link>
            </template>
            <b-form-group v-if="editCurrency">
              <b-form-radio
                class="custom-control-inline"
                name="currency"
                v-model="providerDetails.billing.currency"
                @change="handleInlineEdit('editCurrency')"
                value="usd"
              >
                USD
              </b-form-radio>
              <b-form-radio
                class="custom-control-inline"
                name="currency"
                v-model="providerDetails.billing.currency"
                @change="handleInlineEdit('editCurrency')"
                value="cad"
                disabled
              >
                CAD (coming soon)
              </b-form-radio>
            </b-form-group>
            <base-button
              size="sm"
              type="secondary"
              class="mt--4"
              v-if="editCurrency"
              @click="editCurrency = false"
              >Cancel</base-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h6 class="heading-small text-muted mb-0">US Bank Account Details</h6>
          <div class="text-sm mb-3">
            <template v-if="!editUSBank">
              Routing number:
              {{
                (providerDetails.billing &&
                  providerDetails.billing.us_bank_routing_number) ||
                  'n/a'
              }}<br />
              Account number:
              {{
                (providerDetails.billing &&
                  providerDetails.billing.us_bank_account_number &&
                  '&bullet;&bullet;&bullet;&bullet;&bullet;&bullet;' +
                    providerDetails.billing.us_bank_account_number.substring(
                      providerDetails.billing.us_bank_account_number.length - 4
                    )) ||
                  'n/a'
              }}
              <b-link @click="editUSBank = true"
                ><i class="fas fa-pen fa-xs text-gray"
              /></b-link>
            </template>
            <div class="justify-content-between d-flex">
              <base-input
                placeholder="Routing number"
                input-classes="form-control-sm text-sm"
                v-model="providerDetails.billing.us_bank_routing_number"
                v-if="editUSBank"
                :autofocus="editUSBank"
                @keyup.enter="handleInlineEdit('editUSBank')"
              />
              <base-input
                placeholder="Account number"
                input-classes="form-control-sm text-sm ml-1 mr-1"
                v-model="providerDetails.billing.us_bank_account_number"
                v-if="editUSBank"
                @keyup.enter="handleInlineEdit('editUSBank')"
              />
            </div>
            <base-button
              size="sm"
              type="primary"
              v-if="editUSBank"
              class="mt--4"
              @click="handleInlineEdit('editUSBank')"
              >Save</base-button
            >
          </div>
        </b-col>
      </b-row>
    </template>
  </card>
</template>

<script>
import notifications from '@/services/notifications'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'

export default {
  mixins: [providerDetailsMixin],
  data() {
    return {
      setupBilling: false,
      editCurrency: false,
      editUSBank: false
    }
  },
  computed: {
    setupMode() {
      return this.setupBilling || this.providerDetails.billing?.payments_enabled
    }
  },
  created() {
    this.$store.dispatch('provider/fetch')
  },

  watch: {
    providerReady() {
      this.providerDetails.billing = this.providerDetails.billing || {}
    }
  },
  methods: {
    async handleInlineEdit(flag) {
      this.providerDetails.billing.payments_enabled = !!(
        this.providerDetails.billing?.currency &&
        this.providerDetails.billing?.us_bank_routing_number &&
        this.providerDetails.billing?.us_bank_account_number
      )
      try {
        await this.$store.dispatch('provider/save', this.providerDetails)
        this[flag] = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>
