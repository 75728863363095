import moment from 'moment'
import phoneFormatter from 'phone-formatter'
import uniqBy from 'lodash.uniqby'

export default {
  computed: {
    sessionsReady() {
      return this.$store.state.sessions.ready
    },
    sessionDetails() {
      if (!this.sessionId) {
        return {}
      }
      const sessions = this.$store.state.sessions.sessions
      return sessions.find(session => session.session === this.sessionId) || {}
    },
    sessionShowChargeLater() {
      return (
        this.sessionDetails.status !== 'completed' &&
        this.sessionDetails.status !== 'missed' &&
        this.sessionDetails.status !== 'canceled' &&
        this.sessionDetails.payment_status === 'unpaid'
      )
    },
    sessionShowPrePaid() {
      return (
        this.sessionDetails.status !== 'missed' &&
        this.sessionDetails.status !== 'canceled' &&
        this.sessionDetails.payment_status === 'paid'
      )
    },
    sessionGroup() {
      return this.$store.state.sessiongroups.sessiongroupDetails[
        this.sessionDetails.sessiongroup
      ]
    },
    sessionClient() {
      return this.$store.state.clients.clientDetails[this.sessionDetails.client]
    },
    clientName() {
      return this.sessionClient
        ? `${this.sessionClient.first_name || ''} ${this.sessionClient
            .last_name || ''}`
        : ''
    },
    clientEmail() {
      return this.sessionClient.email
    },
    clientPhone() {
      return this.sessionClient.phone && this.sessionClient.phone.length === 10
        ? phoneFormatter.format(this.sessionClient.phone, '(NNN) NNN-NNNN')
        : this.sessionClient.phone
    },
    clientLink1() {
      return this.sessionClient.link_1
    },
    clientLink2() {
      return this.sessionClient.link_2
    },
    clientPayment() {
      return (
        this.sessionClient.meta?.stripe_enabled &&
        this.sessionClient.meta?.stripe_customer &&
        this.sessionClient.meta?.stripe_payment_method
      )
    },
    clientRates() {
      return this.sessionClient?.rates || []
    },
    sessionDate() {
      return moment(this.sessionDetails.start_date_time).format('dddd, MMMM Do')
    },
    sessionStartTime() {
      return moment(this.sessionDetails.start_date_time).format('h:mm a')
    },
    sessionEndTime() {
      return moment(this.sessionDetails.end_date_time).format('h:mm a')
    },
    providerDetails() {
      return this.$store.state.provider.provider || {}
    },
    providerRates() {
      return this.providerDetails.rates || []
    },
    defaultRateNames() {
      return {
        'initial-consult': 'Initial Consult',
        'first-session': 'First Session',
        'regular-session': 'Regular Session',
        'regular-session-2': 'Regular Session'
      }
    },
    rates() {
      return uniqBy([...this.clientRates, ...this.providerRates], 'rate')
    },
    rate() {
      return this.rates.find(x => x.rate === this.sessionDetails.rate)
    },
    rateName() {
      return (
        this.sessionDetails.rate_name ||
        this.rate?.rate_name ||
        this.defaultRateNames[this.sessionDetails.rate]
      )
    },
    rateDuration() {
      return this.sessionDetails.rate_duration || this.rate.rate_duration
    },
    rateAmount() {
      let amount =
        this.sessionDetails.rate_amount || this.rate?.rate_amount || 0
      amount = +amount
      return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      })
    }
  }
}
