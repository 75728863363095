<template>
  <div>
    <template v-if="grouped">
      <div v-for="(group, index) in groups" :key="group">
        <b-row>
          <b-col>
            <div class="justify-content-between d-flex">
              <h4 class="date-group-header">{{ getDateForGroup(group) }}</h4>
              <b-link
                v-if="index == 0"
                style="color: gray"
                @click="toggleCollapses(sessionIDs)"
                class="pt-2 pr-4"
              >
                <span class="font-weight-400">
                  <span class="d-none d-sm-inline">
                    {{ expandSessions }}
                  </span>
                  <i
                    v-if="expandSessions === 'Expand All'"
                    class="fas fa-angle-double-down ml-2"
                  ></i>
                  <i v-else class="fas fa-angle-double-up ml-2"></i>
                </span>
              </b-link>
            </div>
          </b-col>
        </b-row>
        <SessionCard
          v-for="session in groupMap[group]"
          v-on="$listeners"
          :key="session.session"
          :session="session"
          :hide-avatars="hideAvatars"
          :hide-names="hideNames"
          :hide-actions="hideActions"
          :hide-details="hideDetails"
          grouped
        />
      </div>
      <b-col
        cols="12"
        class="d-flex justify-content-center flex-wrap text-sm py-4"
        v-if="!groups.length"
      >
        No data or search results
      </b-col>
    </template>
    <template v-else>
      <SessionCard
        v-for="session in queriedData"
        v-on="$listeners"
        :key="session.session"
        :session="session"
        :hide-avatars="hideAvatars"
        :hide-names="hideNames"
        :hide-actions="hideActions"
        :hide-details="hideDetails"
      />
      <b-col
        cols="12"
        class="d-flex justify-content-center flex-wrap text-sm py-4"
        v-if="!groups.length"
      >
        No sessions found
      </b-col>
    </template>
    <div
      class="mt-4 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      v-if="perPageField"
    >
      <p class="card-category">
        Showing {{ from + 1 }} to {{ to }} of {{ total }} sessions
      </p>
      <base-pagination
        class="pagination-no-border"
        :current="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
        @change="paginationChanged($event)"
      >
      </base-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import searchMixin from '@/mixins/searchMixin'
import SessionCard from './SessionCard.vue'

export default {
  props: {
    grouped: Boolean,
    descending: Boolean,
    hideAvatars: Boolean,
    hideNames: Boolean,
    hideActions: Boolean,
    hideDetails: Boolean,
    collection: Array,
    searchQueryField: String,
    perPageField: Number,
    filterClient: String
  },
  components: {
    SessionCard
  },
  mixins: [searchMixin],
  computed: {
    sessionIDs() {
      return this.queriedData.map(x => 'accordion-' + x.session)
    },
    sessionsReady() {
      return this.$store.state.sessions.ready
    },
    sourceData() {
      let collection = this.collection
      if (this.$route.query.client) {
        collection = collection.filter(
          session => session.client === this.$route.query.client
        )
      }
      return this.descending ? collection.reverse() : collection
    },
    groupMap() {
      const map = {}
      this.queriedData.forEach(session => {
        const doy =
          moment(session.start_date_time).year() * 1000 +
          moment(session.start_date_time).dayOfYear()
        if (!map[doy]) {
          map[doy] = []
        }
        map[doy].push(session)
      })
      return map
    },
    groups() {
      if (this.descending) {
        return Object.keys(this.groupMap).reverse()
      } else {
        return Object.keys(this.groupMap)
      }
    }
  },
  data() {
    return {
      propsToSearch: ['full_name'],
      expandSessions: 'Expand All'
    }
  },
  created() {
    this.$store.dispatch('clients/fetch') // load first to associate clientDetails
    this.$store.dispatch('sessiongroups/fetch')
    this.$store.dispatch('sessions/fetch')

    this.pagination.perPage = this.perPageField || 5
  },
  mounted() {
    if (this.sessionsReady) {
      // nav to page
      this.initTableData()
    } else {
      // first load
      this.$watch('sessionsReady', () => {
        this.initTableData()
      })
    }
  },
  methods: {
    toggleCollapses(ids) {
      this.expandSessions =
        this.expandSessions === 'Expand All' ? 'Collapse All' : 'Expand All'
      ids.forEach(id => {
        this.$root.$emit('bv::toggle::collapse', id)
      })
    },
    getDateForGroup(group) {
      if (!this.groupMap[group] || !this.groupMap[group].length) {
        return ''
      }
      const refDate = moment(this.groupMap[group][0].start_date_time)
      return refDate.format('dddd, MMMM Do')
    }
  },
  watch: {
    searchQueryField(query) {
      this.searchQuery = query
    },
    perPageField(perPage) {
      this.pagination.perPage = perPage
    },
    filterClient() {
      this.paginationChanged(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.date-group-header {
  padding: 10px 20px;
  margin-bottom: 0;
  font-size: 18px;
}
</style>
