export default {
  computed: {
    showModal: {
      get() {
        return !!this.sessionId
      },
      set(value) {
        this.$emit('update:sessionId', value ? this.sessionId : null)
      }
    }
  }
}
