<template>
  <modal :show.sync="showModal" size="sm" body-classes="p-0" hide-footer>
    <h6 slot="header" class="modal-title">
      {{ title }}
    </h6>
    <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-5"
      class="border-0 mb-0"
    >
      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="handleSubmit(save)">
          <base-alert type="warning" v-if="conflictName">
            <strong>Oops!</strong> You already have
            {{ conflictName.first_name }}
            {{ conflictName.last_name }}
          </base-alert>
          <b-row>
            <b-col>
              <base-input
                name="First name"
                placeholder="First name"
                rules="required"
                v-model="fields.first_name"
              />
            </b-col>
            <b-col>
              <base-input
                name="Last name"
                placeholder="Last name"
                v-model="fields.last_name"
              />
            </b-col>
          </b-row>
          <base-input
            name="Email"
            placeholder="Email"
            prepend-icon="fas fa-envelope"
            type="email"
            rules="required"
            autocomplete="username email"
            v-model="fields.email"
          />
          <base-input
            name="Phone"
            placeholder="Phone"
            prepend-icon="fas fa-phone"
            v-model="fields.phone"
          />
          <div class="text-center">
            <base-button type="primary" native-type="submit">
              {{ actionButton }}
            </base-button>
          </div>
        </form>
      </validation-observer>
    </card>
  </modal>
</template>

<script>
import notifications from '@/services/notifications'
import clientDetailsMixin from '@/mixins/clientDetailsMixin'

export default {
  props: ['mode', 'clientId'],
  mixins: [clientDetailsMixin],
  data() {
    return {
      fields: {}
    }
  },
  computed: {
    isNew() {
      return this.mode === 'add'
    },
    title() {
      if (this.isNew) {
        return 'Add New Client'
      }
      return 'Edit Client: ' + this.clientName
    },
    actionButton() {
      if (this.isNew) {
        return 'Add'
      }
      return 'Save'
    },
    conflictName() {
      return this.$store.state.clients.clients.find(
        client =>
          client.first_name === this.fields.first_name &&
          client.last_name === this.fields.last_name
      )
    },
    showModal: {
      get() {
        return !!this.clientId
      },
      set(value) {
        this.$emit('update:clientId', value ? this.clientId : null)
      }
    }
  },
  created() {
    try {
      this.$store.dispatch('clients/fetch')
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  methods: {
    async save() {
      try {
        await this.$store.dispatch('clients/save', this.fields)
        this.showModal = false
        this.fields = {}
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>
