<template>
  <div>
    <base-header class="pb-6"></base-header>
    <b-container fluid class="mt--6 h-100">
      <b-row
        v-if="clientDetails.status === 'inactive'"
        class="row text-center justify-content-center d-flex"
        align-v="center"
      >
        <b-col>
          <p class="text-white">
            <i class="fas fa-exclamation-circle"></i> This client is inactive.
            Click button on the top left corner of the client card to activate
            the client profile
          </p>
        </b-col>
      </b-row>
      <b-row class="row justify-content-between d-flex">
        <b-col sm="3">
          <ClientProfileCard :client-id="clientId" />
          <ClientProfileContacts :client-id="clientId" />
        </b-col>
        <b-col sm="6">
          <card no-body>
            <b-tabs pills>
              <b-tab title="Active Sessions" active>
                <SessionTab
                  :collection="activeSessions"
                  hide-avatars
                  hide-names
                  hide-actions
                  hide-details
                  grouped
                  :perPageField="perPage"
                />
                <card
                  class="mb-0 d-flex justify-content-between"
                  body-classes="p-3"
                >
                  <b-row class="text-center justify-content-end">
                    <b-col cols="6" sm="6">
                      <b-button variant="primary" size="sm" to="/sessions#add">
                        Add New Session
                      </b-button>
                    </b-col>
                    <b-col cols="6" sm="3">
                      <router-link
                        class="text-right"
                        :to="{
                          path: '/sessions',
                          query: { client: clientDetails.client, tab: 'active' }
                        }"
                      >
                        All Active Sessions &raquo;
                      </router-link>
                    </b-col>
                  </b-row>
                </card>
              </b-tab>
              <b-tab title="Done">
                <SessionTab
                  :collection="doneSessions"
                  descending
                  hide-avatars
                  hide-names
                  hide-actions
                  hide-details
                  grouped
                  :perPageField="perPage"
                />
              </b-tab>

              <b-tab title="History">
                <card class="mb-0">
                  <p class="font-weight-400">Coming soon!</p>
                  <!-- <time-line type="one-side">
                    <time-line-item
                      badge-type="secondary"
                      badge-icon="ni ni-bell-55 text-gray"
                      v-for="event in events"
                      :key="event.id"
                    >
                      <small class="text-muted font-weight-bold"
                        >{{ event.date }} @ {{ event.time }}</small
                      >
                      <h5 class=" mt-3 mb-0">{{ event.name }}</h5>
                      <p class=" text-sm mt-1 mb-0">
                        {{ event.description }}
                      </p>
                      <div class="mt-3">
                        <badge rounded type="secondary" style="color: gray">{{
                          event.category
                        }}</badge>
                      </div>
                    </time-line-item>
                  </time-line> -->
                </card>
              </b-tab>
            </b-tabs>
          </card>
          <b-row>
            <b-col>
              <ClientProfileRates :client-id="clientId" />
            </b-col>
          </b-row>
          <Stats :client-id="clientId" />
        </b-col>
        <b-col sm="3">
          <card body-classes="py-1">
            <b-row align-v="center" slot="header">
              <b-col>
                <h3 class="mb-0">
                  Client Tasks
                  <b-badge style="background-color: #ffe07c">Private</b-badge>
                  <b-link
                    style="color: black"
                    @click="toggleTasks()"
                    class="pt-2 pl-4"
                  >
                    <span class="font-weight-400">
                      <p class="d-none d-sm-inline float-right mb-0">
                        {{ showTasks }}
                      </p>
                    </span>
                  </b-link>
                </h3>
              </b-col>
            </b-row>
            <template v-if="showTasks === 'Show Completed'">
              <Tasks :client-id.sync="clientId" showIncomplete />
            </template>
            <template v-else>
              <Tasks :client-id.sync="clientId" showCompleted />
            </template>
          </card>

          <ClientProfileNotes :client-id="clientId" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ClientProfileCard from '../Sections/ClientProfile/ClientProfileCard.vue'
import ClientProfileContacts from '../Sections/ClientProfile/ClientProfileContacts.vue'
import SessionTab from '../Sections/Sessions/SessionTab.vue'
import ClientProfileRates from '../Sections/ClientProfile/ClientProfileRates.vue'
import Stats from '../Sections/Shared/Stats.vue'
import Tasks from '../Sections/Shared/Tasks.vue'
import ClientProfileNotes from '../Sections/ClientProfile/ClientProfileNotes.vue'
import notifications from '@/services/notifications'
import clientDetailsMixin from '@/mixins/clientDetailsMixin'

export default {
  props: {
    showAll: Boolean,
    showCompleted: Boolean,
    showIncomplete: Boolean
  },
  components: {
    ClientProfileCard,
    ClientProfileContacts,
    SessionTab,
    ClientProfileRates,
    Stats,
    Tasks,
    ClientProfileNotes
  },
  mixins: [clientDetailsMixin],
  data() {
    return {
      showTasks: 'Show Completed',
      perPage: 25
    }
  },
  computed: {
    clientId() {
      return this.$route.params.id
    }
  },
  created() {
    try {
      this.$store.dispatch('clients/fetchClientDetails', this.clientId)
      this.$store.dispatch('sessiongroups/fetch')
      this.$store.dispatch('sessions/fetch')
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
    document.title = `${this.clientName} - Client Profile » Zenclear`
  },
  methods: {
    toggleTasks() {
      this.showTasks =
        this.showTasks === 'Show Completed'
          ? 'Hide Completed'
          : 'Show Completed'
    }
  }
}
</script>

<style>
.card-stats .h3 {
  font-size: 0.875rem;
}
</style>
