<template>
  <card>
    <b-row align-v="center" slot="header">
      <b-col>
        <h3 class="mb-0">Contact Details</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0 mt--1">Name</h6>
        <div class="text-sm mb-3">
          <template v-if="!editName">
            {{ clientName || 'Add name...' }}
            <b-link @click="editName = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <div class="justify-content-between d-flex">
            <base-input
              placeholder="First name"
              input-classes="form-control-sm text-sm"
              v-model="clientDetails.first_name"
              v-if="editName"
              :autofocus="editName"
              @keyup.enter="handleInlineEdit('editName')"
            />
            <base-input
              placeholder="Last name"
              input-classes="form-control-sm text-sm ml-1 mr-1"
              v-model="clientDetails.last_name"
              v-if="editName"
              @keyup.enter="handleInlineEdit('editName')"
            />
          </div>
          <base-button
            size="sm"
            type="primary"
            v-if="editName"
            class="mt--4"
            @click="handleInlineEdit('editName')"
            >Save</base-button
          >
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0 mt--1">Phone</h6>
        <div class="text-sm mb-3">
          <template v-if="!editPhone">
            {{ clientPhone || 'Add phone...' }}
            <b-link @click="editPhone = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="(408) 555-1212"
            input-classes="form-control-sm text-sm"
            v-model="clientDetails.phone"
            v-if="editPhone"
            :autofocus="editPhone"
            @keyup.enter="handleInlineEdit('editPhone')"
            @blur="handleInlineEdit('editPhone')"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="heading-small text-muted mb-0">Email</h6>
        <div class="text-sm mb-3">
          <template v-if="!editEmail">
            {{ clientEmail }}
            <b-link @click="editEmail = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input
            placeholder="someone@example.com"
            input-classes="form-control-sm text-sm"
            v-model="clientDetails.email"
            v-if="editEmail"
            :autofocus="editEmail"
            @keyup.enter="handleInlineEdit('editEmail')"
            @blur="handleInlineEdit('editEmail')"
          />
        </div>
      </b-col>
    </b-row>
    <b-card-title class="my-2">
      Additional Contacts
      <b-link
        @click="editAdditionalContacts = true"
        v-if="!editAdditionalContacts"
        ><i class="fas fa-pen fa-xs text-gray"/></b-link
    ></b-card-title>
    <template v-if="!editAdditionalContacts">
      <template v-for="(contact, i) in clientContacts">
        <b-row :key="i" class="mb-2">
          <b-col>
            <h6 class="heading-small text-muted mb-0">Contact #{{ i + 1 }}</h6>
            <div class="text-sm mb-2">
              {{ contact.name || 'No name' }}
            </div>
            <div class="text-sm mb-2">
              {{ formatPhone(contact.phone) || 'No phone' }}
            </div>
            <div class="text-sm mb-2">
              {{ contact.email || 'No email' }}
            </div>
          </b-col>
        </b-row>
      </template>
    </template>
    <template v-if="editAdditionalContacts">
      <template v-for="(contact, i) in clientContacts">
        <b-row :key="'name' + i" id="contacts" class="mb-2">
          <b-col>
            <h6 class="heading-small text-muted mb-0">Contact #{{ i + 1 }}</h6>
            <base-input
              placeholder="Lee, Dad, Case Manager, ..."
              input-classes="form-control-sm text-sm"
              v-model="contact.name"
            />
            <base-input
              placeholder="(408) 555-1212"
              input-classes="form-control-sm text-sm"
              v-model="contact.phone"
            />
            <base-input
              placeholder="someone@example.com"
              input-classes="form-control-sm text-sm"
              v-model="contact.email"
            />
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col class="d-flex justify-content-between">
          <base-button
            outline
            size="sm"
            type="primary"
            @click="clientContacts.push({})"
            >Add Another</base-button
          >
          <base-button
            size="sm"
            type="primary"
            @click="handleEditAdditionalContacts"
            >Save</base-button
          >
        </b-col>
      </b-row>
    </template>
  </card>
</template>

<script>
import notifications from '@/services/notifications'
import clientDetailsMixin from '@/mixins/clientDetailsMixin'
import phoneFormatter from 'phone-formatter'

export default {
  props: ['clientId'],
  mixins: [clientDetailsMixin],
  data() {
    return {
      editName: false,
      editPhone: false,
      editEmail: false,
      editNotes: false,
      editAdditionalContacts: false
    }
  },
  created() {
    try {
      this.$store.dispatch('clients/fetchClientDetails', this.clientId)
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  methods: {
    formatPhone(phone) {
      return phone && phone.length === 10
        ? phoneFormatter.format(phone, '(NNN) NNN-NNNN')
        : phone
    },
    async handleInlineEdit(flag) {
      try {
        await this.$store.dispatch('clients/save', this.clientDetails)
        this[flag] = false

        // update all sessions that have cached this client_details
        await this.$store.dispatch('sessions/updateClientDetails', {
          clientId: this.clientDetails.client,
          clientDetails: {
            name:
              this.clientDetails.first_name +
              ' ' +
              this.clientDetails.last_name,
            email: this.clientDetails.email,
            phone: this.clientDetails.phone
          }
        })
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    },
    async handleEditAdditionalContacts() {
      // cleanup blank items in clientContacts
      this.clientContacts = this.clientContacts.filter(contact => {
        return contact.name || contact.phone || contact.email // must have at least 1 thing set
      })

      try {
        await this.$store.dispatch('clients/save', this.clientDetails)
        this.editAdditionalContacts = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>

<style>
#contacts .form-group {
  margin-bottom: 0.5rem;
}
</style>
