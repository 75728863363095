<template>
  <card>
    <b-row align-v="center" slot="header">
      <b-col>
        <h3 class="mb-0">
          Client Notes
          <b-badge style="background-color: #ffe07c">Private</b-badge>
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="text-sm mb-3" style="white-space: pre-line;">
          <template v-if="!editNotes">
            {{ clientDetails.notes || 'Add private notes about client...' }}
            <b-link @click="editNotes = true"
              ><i class="fas fa-pen fa-xs text-gray"
            /></b-link>
          </template>
          <base-input v-if="editNotes">
            <textarea
              rows="3"
              placeholder="Notes about client..."
              class="form-control text-sm beige-text"
              v-model="clientDetails.notes"
              @keyup.esc="handleInlineEdit('editNotes')"
              @blur="handleInlineEdit('editNotes')"
            />
          </base-input>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="editNotes">
      <b-col class="d-flex justify-content-between">
        <base-button
          size="sm"
          type="primary"
          @click="handleInlineEdit('editNotes')"
          >Save</base-button
        >
      </b-col>
    </b-row>
  </card>
</template>

<script>
import notifications from '@/services/notifications'
import clientDetailsMixin from '@/mixins/clientDetailsMixin'

export default {
  props: ['clientId'],
  mixins: [clientDetailsMixin],
  data() {
    return {
      editNotes: false
    }
  },
  created() {
    try {
      this.$store.dispatch('clients/fetchClientDetails', this.clientId)
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  methods: {
    async handleInlineEdit(flag) {
      try {
        await this.$store.dispatch('clients/save', this.clientDetails)
        this[flag] = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>
