<template>
  <div>
    <base-header class="pb-6"></base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col lg="6">
          <card header-classes="bg-transparent">
            <h1 slot="header" class="mb-0">Calendar View</h1>
            <p class="text-muted mt-0 mb-5">
              This high-level view of all the upcoming sessions (exclude missed,
              cancelled, completed)
            </p>
            <time-line type="one-side">
              <time-line-item
                badge-type="success"
                badge-icon="ni ni-bell-55"
                v-for="event in events"
                :key="event.id"
              >
                <small class="text-muted font-weight-bold"
                  >{{ event.date }} @ {{ event.time }}</small
                >
                <h5 class=" mt-3 mb-0">{{ event.name }}</h5>
                <p class=" text-sm mt-1 mb-0">
                  {{ event.description }}
                </p>
                <div class="mt-3">
                  <badge rounded type="success">{{ event.category }}</badge>
                </div>
              </time-line-item>
            </time-line>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem } from '@/components'
import apiService from '@/services/apiService'
import notifications from '@/services/notifications'

export default {
  components: {
    TimeLine,
    TimeLineItem
  },
  data() {
    return {
      events: []
    }
  },
  async created() {
    try {
      this.events = await apiService.getEvents()
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  }
}
</script>
<style></style>
