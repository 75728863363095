<template>
  <card>
    <b-row align-v="center" slot="header">
      <b-col>
        <h3 class="mb-0">
          Default Session Rates
          <b-link @click="editRates = true" v-if="!editRates"
            ><i class="fas fa-pen fa-xs text-gray"
          /></b-link>
        </h3>
      </b-col>
    </b-row>
    <template v-if="!editRates">
      <base-alert type="warning" v-if="!providerHasRates && providerReady">
        <i class="fas fa-exclamation-triangle" /> Please define at least 1
        session rate
      </base-alert>
      <template v-for="(rate, i) in fieldRates">
        <b-row :key="i">
          <b-col>
            <h6 class="heading-small text-muted mb-0">{{ rate.rate_name }}</h6>
            <div
              class="text-sm mb-3"
              v-if="rate.rate_amount && rate.rate_duration"
            >
              ${{ rate.rate_amount || defaultRateAmount(rate.rate) }} for
              {{ rate.rate_duration || defaultRateDuration(rate.rate) }} min
            </div>
            <div class="text-sm mb-3" v-else>
              Rate not set
            </div>
          </b-col>
        </b-row>
      </template>
    </template>
    <template v-if="editRates">
      <template v-for="(rate, i) in fieldRates">
        <b-row :key="'name' + i" class="mb-3">
          <b-col style="padding-right: 10px;">
            <h6 class="heading-small text-muted">{{ rate.rate_name }}<br /></h6>
            <b-input-group prepend="$">
              <b-form-input
                v-model="rate.rate_amount"
                :placeholder="
                  `(eg. ${defaultRateAmount(rate.rate).toString()})`
                "
              />
            </b-input-group>
          </b-col>
          <b-col style="padding-left: 0;">
            <h6 class="heading-small text-muted"><br /></h6>
            <b-input-group prepend="for" append="min">
              <b-form-input
                v-model="rate.rate_duration"
                :placeholder="
                  `(eg. ${defaultRateDuration(rate.rate).toString()})`
                "
              />
            </b-input-group>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col class="d-flex justify-content-between">
          <base-button
            outline
            size="sm"
            type="primary"
            @click="providerRates.push({})"
            v-if="false"
            >Add Another</base-button
          >
          <base-button size="sm" type="primary" @click="handleEditRates"
            >Save</base-button
          >
        </b-col>
      </b-row>
    </template>
    <b-row slot="footer">
      <b-col>
        <p>
          Session rates must be defined (at least one) in order to schedule
          client sessions. Theses are the default rates for all clients.
        </p>
        <p>
          If you need to customize a specific client's rate (eg. special
          discounts), you can do this by going to the Client's Profile &gt;
          Session Rates &gt; Edit. You'll see it change from default to custom.
          New sessions created for that client will use their custom rate.
        </p>
      </b-col>
    </b-row>
  </card>
</template>

<script>
import Vue from 'vue'
import notifications from '@/services/notifications'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'

export default {
  mixins: [providerDetailsMixin],
  data() {
    return {
      editRates: false,
      fields: []
    }
  },
  computed: {
    fieldRates: {
      get() {
        // ensure each of the default 4 rates are in the array (even if blank)
        const fields = [...this.providerRates]
        if (!fields.find(x => x.rate === 'initial-consult')) {
          fields.push({
            rate: 'initial-consult',
            rate_name: 'Initial Consult'
          })
        }
        if (!fields.find(x => x.rate === 'first-session')) {
          fields.push({
            rate: 'first-session',
            rate_name: 'First Session'
          })
        }
        if (!fields.find(x => x.rate === 'regular-session')) {
          fields.push({
            rate: 'regular-session',
            rate_name: 'Regular Session'
          })
        }
        if (!fields.find(x => x.rate === 'regular-session-2')) {
          fields.push({
            rate: 'regular-session-2',
            rate_name: 'Regular Session'
          })
        }
        return fields
      },
      set(value) {
        Vue.set(this, 'fields', value)
      }
    }
  },
  created() {
    this.$store.dispatch('provider/fetch')
  },
  methods: {
    defaultRateAmount(rate) {
      if (rate) {
        return this.sampleRates.find(x => x.rate === rate)?.rate_amount
      }
      return ''
    },
    defaultRateDuration(rate) {
      if (rate) {
        return this.sampleRates.find(x => x.rate === rate)?.rate_duration
      }
      return ''
    },
    async handleEditRates() {
      // cleanup blank items in providerRates, leaving empty array
      this.providerRates = this.fieldRates.filter(x => {
        return x.rate_amount && x.rate_duration
      })

      try {
        await this.$store.dispatch('provider/save', this.providerDetails)
        this.editRates = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>
