<template>
  <modal :show.sync="showModal" size="lg" body-classes="p-0" hide-footer>
    <h6 slot="header" class="modal-title">
      {{ title }}
    </h6>
    <card
      type="secondary"
      header-classes="bg-transparent pb-5"
      body-classes="px-lg-5"
      class="border-0 mb-0"
    >
      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <form @submit.prevent="handleSubmit(save)">
          <base-alert type="warning" v-if="conflictMessage">
            <strong>Warning!</strong> {{ conflictMessage }}
          </base-alert>
          <b-row>
            <b-col cols="12" sm="6">
              <base-input label="Client" name="Client" rules="required">
                <el-select
                  v-model="currentClient"
                  filterable
                  rules="required"
                  placeholder="Choose client..."
                >
                  <el-option
                    v-for="client in clients"
                    :key="client.client"
                    :label="client.first_name + ' ' + client.last_name"
                    :value="client.client"
                  />
                </el-select>
              </base-input>
            </b-col>
            <b-col cols="12" sm="6">
              <base-input
                label="Session Type"
                name="Session Type"
                rules="required"
              >
                <el-select
                  min-height="100"
                  v-model="sessionRate"
                  filterable
                  placeholder="Choose type..."
                >
                  <el-option
                    v-for="option in rates"
                    :key="option.rate"
                    :label="
                      option.rate_name + ' (' + option.rate_duration + 'min)'
                    "
                    :value="option.rate"
                  />
                </el-select>
              </base-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="6">
              <base-input
                v-model="sessionStart"
                label="Date"
                name="Date"
                rules="required"
                type="datetime-local"
              />
            </b-col>
            <b-col cols="8" sm="4" style="padding-right: 10px;">
              <h4 class="form-control-label">Rate</h4>
              <b-input-group prepend="$" :append="customRate">
                <b-form-input
                  v-model="fields.rate_amount"
                  :placeholder="defaultRateAmount.toString()"
                />
              </b-input-group>
            </b-col>
            <b-col cols="4" sm="2" style="padding-left: 0;">
              <h4 class="form-control-label">Duration</h4>
              <b-input-group append="min">
                <b-form-input
                  v-model="fields.rate_duration"
                  :placeholder="defaultRateDuration.toString()"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row v-if="featureFlag.sessions_repeat">
            <b-col cols="12" sm="4" v-if="!isNew && sessionDetails.repeat">
              <label class="form-control-label d-none d-sm-block"
                >Scheduling Options</label
              >
              <label class="form-control-label pt-4 d-block d-sm-none"
                >Scheduling Options</label
              >
              <p class="font-weight-400">Repeats</p>
            </b-col>
            <b-col cols="12" sm="4" v-else>
              <h4 class="form-control-label d-none d-sm-block">
                Scheduling Options
              </h4>
              <h4 class="form-control-label pt-4 d-block d-sm-none">
                Scheduling Options
              </h4>
              <base-input
                class="pt-2"
                name="Scheduling Options"
                rules="required"
              >
                <b-form-radio-group
                  v-model="fields.repeat"
                  :options="repeatOptions"
                  required
                  stacked
                />
              </base-input>
            </b-col>
            <b-col cols="12" sm="4" v-if="fields.repeat">
              <base-input label="Frequency" name="Frequency" rules="required">
                <el-select
                  v-model="fields.frequency"
                  filterable
                  placeholder="Choose frequency..."
                >
                  <el-option
                    v-for="item in frequencyOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </base-input>
            </b-col>
            <b-col cols="12" sm="4" v-if="fields.repeat">
              <base-input label="End Repeat" name="End Repeat" rules="required">
                <el-select
                  v-model="fields.end_count"
                  filterable
                  placeholder="After __ sessions..."
                >
                  <el-option
                    v-for="item in endCountOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </base-input>
            </b-col>
          </b-row>
          <b-row
            v-if="
              featureFlag.sessions_repeat &&
                fields.repeat &&
                fields.frequency &&
                fields.end_count != null
            "
            class="mt--2"
          >
            <b-col cols="12" class="text-center">
              <p>
                <i class="fas fa-info-circle" />
                Zenclear will schedule
                <template v-if="fields.end_count === -1">
                  the next 8 {{ fields.frequency }} sessions to start and then
                  {{ fields.frequency }} after that automatically
                </template>
                <template v-else>
                  a total of {{ fields.end_count + 1 }}
                  {{ fields.frequency }} sessions ending on
                  {{ repeatFinalDateTime }}
                </template>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <base-input
                label="Location"
                name="Location"
                placeholder="(optional, eg. https://zoom.us/j/1234567890, office address, ...)"
                v-model="fields.location"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <base-input
                label="Session Notes (Private)"
                name="Session Notes (Private)"
              >
                <textarea
                  class="form-control beige-text"
                  placeholder="(optional, eg. session agenda, lesson plans, ...)"
                  v-model="fields.notes"
                  rows="3"
                ></textarea>
              </base-input>
            </b-col>
          </b-row>
          <b-row v-if="featureFlag.billing_code">
            <b-col cols="12">
              <base-input
                v-model="fields.meta.billing_code"
                label="Billing Code"
                name="Billing Code"
                placeholder="(optional)"
              />
            </b-col>
          </b-row>
          <div class="text-center">
            <base-button type="primary" native-type="submit">
              {{ actionButton }}
            </base-button>
          </div>
        </form>
      </validation-observer>
    </card>
  </modal>
</template>

<script>
import Vue from 'vue'
import swal from 'sweetalert2'
import apiService from '@/services/apiService'
import notifications from '@/services/notifications'
import providerDetailsMixin from '@/mixins/providerDetailsMixin'
import sessionModalMixin from '@/mixins/sessionModalMixin'
import sessionDetailsMixin from '@/mixins/sessionDetailsMixin'
import { convertTZ, convertLocale } from '@/util/time'
import { isDiff } from '@/util/diff'
import uniqBy from 'lodash.uniqby'
import debounce from 'lodash.debounce'
import moment from 'moment'

const REPEAT_OPTIONS = [
  {
    text: 'Does not repeat',
    value: false
  },
  {
    text: 'Repeats',
    value: true
  }
]

const FREQUENCY_OPTIONS = [
  // {
  //   label: 'Daily',
  //   value: 'daily'
  // },
  {
    label: 'Weekly',
    value: 'weekly'
  },
  {
    label: 'Bi-Weekly',
    value: 'bi-weekly'
  }
  // {
  //   label: 'Monthly',
  //   value: 'monthly'
  // },
  // {
  //   label: 'Quarterly',
  //   value: 'quarterly'
  // },
  // {
  //   label: 'Annually',
  //   value: 'annually'
  // }
]

const END_COUNT_OPTIONS = [
  // { label: 'Never', value: -1 },
  { label: 'After 2 sessions', value: 1 },
  { label: 'After 3 sessions', value: 2 },
  { label: 'After 4 sessions', value: 3 },
  { label: 'After 5 sessions', value: 4 },
  { label: 'After 6 sessions', value: 5 },
  { label: 'After 7 sessions', value: 6 },
  { label: 'After 8 sessions', value: 7 },
  { label: 'After 9 sessions', value: 8 },
  { label: 'After 10 sessions', value: 9 },
  { label: 'After 11 sessions', value: 10 },
  { label: 'After 12 sessions', value: 11 },
  { label: 'After 13 sessions', value: 12 },
  { label: 'After 14 sessions', value: 13 },
  { label: 'After 15 sessions', value: 14 },
  { label: 'After 16 sessions', value: 15 },
  { label: 'After 17 sessions', value: 16 },
  { label: 'After 18 sessions', value: 17 },
  { label: 'After 19 sessions', value: 18 },
  { label: 'After 20 sessions', value: 19 },
  { label: 'After 21 sessions', value: 20 },
  { label: 'After 22 sessions', value: 21 },
  { label: 'After 23 sessions', value: 22 },
  { label: 'After 24 sessions', value: 23 },
  { label: 'After 25 sessions', value: 24 },
  { label: 'After 26 sessions', value: 25 },
  { label: 'After 52 sessions', value: 51 },
  { label: 'After 104 sessions', value: 103 }
]

const FREQUENCY_MS = {
  daily: 86400000,
  weekly: 86400000 * 7,
  'bi-weekly': 86400000 * 14,
  monthly: 86400000 * 28,
  quarterly: 86400000 * 91,
  annually: 86400000 * 365
}

export default {
  props: ['mode', 'sessionId'],
  mixins: [providerDetailsMixin, sessionModalMixin, sessionDetailsMixin],
  data() {
    return {
      fields: {},
      conflict: null,
      conflictMessage: null,
      repeatOptions: REPEAT_OPTIONS,
      frequencyOptions: FREQUENCY_OPTIONS,
      endCountOptions: END_COUNT_OPTIONS
    }
  },
  computed: {
    isNew() {
      return this.mode === 'add'
    },
    isRescheduling() {
      return this.mode === 'reschedule'
    },
    title() {
      if (this.isNew) {
        return 'Add New Session'
      }
      if (this.isRescheduling) {
        return `Reschedule Session: ${this.clientName} (${this.sessionDate} ${this.sessionStartTime} - ${this.sessionEndTime})`
      }
      return `Edit Session: ${this.clientName} (${this.sessionDate} ${this.sessionStartTime} - ${this.sessionEndTime})`
    },
    actionButton() {
      if (this.isNew) {
        return 'Add'
      }
      return 'Save'
    },
    clients() {
      if (this.isNew) {
        return this.$store.getters['clients/activeClients']
      } else {
        return this.$store.getters['clients/clientCollection']
      }
    },
    repeatFinalDateTime() {
      return moment(
        new Date(
          new Date(this.fields.start_date_time).getTime() +
            FREQUENCY_MS[this.fields.frequency] *
              (this.fields.end_count -
                (this.fields.sessiongroup_session_index || 0))
        )
      ).format('dddd, MMMM Do')
    },
    rates() {
      return uniqBy([...this.currentClientRates, ...this.providerRates], 'rate')
    },
    customRate() {
      return (this.fields.rate_amount &&
        this.fields.rate_amount.toString() !==
          this.defaultRateAmount.toString()) ||
        (this.fields.rate_duration &&
          this.fields.rate_duration.toString() !==
            this.defaultRateDuration.toString())
        ? '(custom)'
        : '(default)'
    },
    defaultRateName() {
      if (this.fields.rate) {
        return this.providerRates.find(x => x.rate === this.fields.rate)
          ?.rate_name
      }
      return ''
    },
    defaultRateAmount() {
      if (this.fields.rate) {
        return (
          this.providerRates.find(x => x.rate === this.fields.rate)
            ?.rate_amount || ''
        )
      }
      return ''
    },
    defaultRateDuration() {
      if (this.fields.rate) {
        return (
          this.providerRates.find(x => x.rate === this.fields.rate)
            ?.rate_duration || ''
        )
      }
      return ''
    },
    conflictClient() {
      if (!this.conflict) {
        return null
      }
      return this.clients.find(x => x.client === this.conflict.client)
    },
    providerDetails() {
      return this.$store.state.provider.provider || {}
    },
    currentClientDetails() {
      return this.$store.state.clients.clientDetails[this.fields.client]
    },
    currentClientRates() {
      return this.currentClientDetails?.rates || []
    },
    currentClient: {
      get() {
        return this.fields.client
      },
      set(client) {
        Vue.set(this.fields, 'client', client)
      }
    },
    sessionRate: {
      get() {
        return this.fields.rate
      },
      set(rate) {
        Vue.set(this.fields, 'rate', rate)
      }
    },
    sessionStart: {
      get() {
        return this.fields.start_date_time
      },
      set(start) {
        Vue.set(this.fields, 'start_date_time', start)
      }
    }
  },
  created() {
    try {
      this.$store.dispatch('provider/fetch')
      this.$store.dispatch('clients/fetch') // load first to associate clientDetails
      this.$store.dispatch('sessiongroups/fetch')
      this.$store.dispatch('sessions/fetch')
    } catch (e) {
      this.$notify(notifications.defaultWarning(e))
    }
  },
  watch: {
    async sessionDetails() {
      if (this.isNew) {
        this.fields = { ...this.fields, ...this.sessionDetails } // clone (but save previous input)
        if (this.providerDetails.session_location) {
          // default session location to provider default location
          this.fields.location = this.providerDetails.session_location
        }
        this.conflictMessage = null // clear conflict notice from previous popups
        this.fields.repeat = false
      } else {
        this.fields = { ...this.sessionDetails } // clone
      }
      if (this.fields.start_date_time) {
        this.fields.start_date_time = convertLocale(this.fields.start_date_time)
      }
      if (!this.fields.meta) {
        this.fields.meta = {}
      }
      if (this.fields.repeat && this.fields.sessiongroup) {
        const sessiongroup = await this.$store.dispatch(
          'sessiongroups/fetchSessionGroupDetails',
          this.fields.sessiongroup
        )
        Vue.set(
          this.fields,
          'frequency',
          sessiongroup.repeat_options?.frequency
        )
        this.sessionDetails.frequency = sessiongroup.repeat_options?.frequency
        Vue.set(
          this.fields,
          'end_count',
          sessiongroup.repeat_options?.end_count
        )
        this.sessionDetails.end_count = sessiongroup.repeat_options?.end_count
      }
    },
    currentClient() {
      this.recalculateRate()
    },
    sessionRate() {
      this.recalculateRate()
    },
    async sessionStart(newStart) {
      try {
        const converted = convertTZ(newStart, this.$auth.user.data.timezone)
        const { data } = await apiService.editEndpoint(
          'misc',
          'check_availability',
          {
            start_date_time: converted,
            end_date_time: new Date(
              converted.getTime() + this.fields.rate_duration * 60 * 1000
            )
          }
        )
        this.conflict = data
        if (this.conflict && this.conflict.client !== this.fields.client) {
          const conflictTime = new Date(this.conflict.start_date_time)
            .toLocaleTimeString()
            .replace(/:00 /, ' ')

          this.conflictMessage = `Scheduling conflict with ${this.conflictClient.first_name} ${this.conflictClient.last_name}'s session at ${conflictTime}`
        } else {
          this.conflictMessage = null
        }
      } catch (ex) {
        console.warn(ex)
      }
    }
  },
  methods: {
    recalculateRate: debounce(
      function() {
        // if custom rate set, only update rate_amount/rate_duration fields if session type changes
        const originalRate = this.rates.find(
          x => x.rate === this.sessionDetails.rate
        )
        const originalCustomRate =
          !originalRate ||
          (this.sessionDetails.rate_amount &&
            this.sessionDetails.rate_amount.toString() !==
              originalRate.rate_amount.toString()) ||
          (this.sessionDetails.rate_duration &&
            this.sessionDetails.rate_duration.toString() !==
              originalRate.rate_duration.toString())

        const rate = this.rates.find(x => x.rate === this.sessionRate)
        if (rate && (rate !== originalRate || !originalCustomRate)) {
          // no custom rate or different session type
          console.log(
            `${this.currentClient || 'default'} ${rate.rate_name}: $${
              rate.rate_amount
            } ${rate.rate_duration}min`
          )
          Vue.set(this.fields, 'rate_name', rate.rate_name)
          Vue.set(this.fields, 'rate_amount', rate.rate_amount)
          Vue.set(this.fields, 'rate_duration', rate.rate_duration)
        } else if (rate && rate === originalRate && originalCustomRate) {
          // reapply original custom rate if choosing same session type again
          console.log(
            `${this.currentClient || 'default'} ${rate.rate_name}: $${
              rate.rate_amount
            } ${rate.rate_duration}min vs custom rate of $${
              this.sessionDetails.rate_amount
            } ${this.sessionDetails.rate_duration}min`
          )
          Vue.set(this.fields, 'rate_name', this.sessionDetails.rate_name)
          Vue.set(this.fields, 'rate_amount', this.sessionDetails.rate_amount)
          Vue.set(
            this.fields,
            'rate_duration',
            this.sessionDetails.rate_duration
          )
        }

        // show custom rate (if selected)
        if (this.currentClient && this.currentClientRates?.length) {
          const currentClientRate = this.currentClientRates.find(
            x => x.rate === this.sessionRate
          )
          if (currentClientRate) {
            console.log(
              `${this.currentClient} Custom Rate for ${currentClientRate.rate_name}: $${currentClientRate.rate_amount} ${currentClientRate.rate_duration}min`
            )
          }
        }
      },
      500,
      {
        leading: true,
        trailing: false
      }
    ),
    async popupSingleOrFuture() {
      return await swal.fire({
        title: '<h2 class="mt-3">Modifying Repeating Session</h2>',
        iconHtml: '<i class="fas fa-edit fa-2x ml-4 text-primary"/>',
        html: `Do you want to modify only this session, or all future sessions?<br /><br />(Choosing 'All future sessions will' remove any previous edits in future sessions.)`,
        showDenyButton: true,
        showCancelButton: true,
        customClass: {
          icon: 'no-border',
          confirmButton: 'btn btn-primary btn-fill btn-block mb-1 mr-0',
          denyButton: 'btn btn-secondary btn-fill btn-block mb-2 mr-0',
          cancelButton: 'btn btn-secondary btn-fill btn-block'
        },
        confirmButtonText: 'Only This Session',
        denyButtonText: 'All Future Sessions',
        buttonsStyling: false,
        keydownListenerCapture: true, // dont close other modals
        width: '24rem'
      })
    },
    async save() {
      // always fill in defaults, rate_name, rate_amount, rate_duration & end_date_time
      if (!this.fields.rate_name) {
        this.fields.rate_name = this.defaultRateName
      }
      if (!this.fields.rate_amount) {
        this.fields.rate_amount = this.defaultRateAmount
      }
      if (!this.fields.rate_duration) {
        this.fields.rate_duration = this.defaultRateDuration
      }

      // fill in denormalized data
      if (this.currentClientDetails) {
        this.fields.client_details = {
          name:
            this.currentClientDetails.first_name +
            ' ' +
            this.currentClientDetails.last_name,
          email: this.currentClientDetails.email,
          phone: this.currentClientDetails.phone || undefined
        }
      }
      if (this.providerDetails) {
        this.fields.provider_details = {
          name:
            this.providerDetails.business_name ||
            this.providerDetails.first_name +
              ' ' +
              this.providerDetails.last_name,
          email:
            this.providerDetails.business_email || this.providerDetails.email,
          phone:
            this.providerDetails.business_phone ||
            this.providerDetails.phone ||
            undefined,
          timezone: this.providerDetails.timezone
        }
      }

      const startDateTime = convertTZ(
        this.fields.start_date_time,
        this.$auth.user.data.timezone
      )
      const endDateTime = new Date(
        startDateTime.getTime() + this.fields.rate_duration * 60 * 1000
      )

      if (this.isNew) {
        // new session
        this.fields.payment_status = 'unpaid'
        this.fields.status = 'scheduled'
        this.fields.scheduled_date_time = new Date().toISOString()
      } else if (
        !this.isNew &&
        this.fields.status === 'scheduled' &&
        startDateTime.getTime() > Date.now()
      ) {
        // update scheduled_date_time on create and for scheduled, future sessions
        this.fields.scheduled_date_time = new Date().toISOString()
      }

      // commited dates consistently in ISO format using new object w/o polluting this.fields (which causes console errors with datepicker)
      const session = {
        ...this.fields,
        ...{
          start_date_time: startDateTime.toISOString(),
          end_date_time: endDateTime.toISOString()
        }
      }

      let askRepeatPopup = false

      if (!this.sessionDetails.repeat && session.repeat) {
        // new repeating sessions
        const sessiongroup = {
          repeat: true,
          repeat_options: {
            active: true,
            frequency: session.frequency,
            end_count: session.end_count,
            cloned_session_date_time: session.start_date_time
          }
        }
        const sessiongroupid = await this.$store.dispatch(
          'sessiongroups/save',
          sessiongroup
        )
        session.sessiongroup = sessiongroupid
        session.sessiongroup_session_index = 0
      } else if (
        // editing existing repeating sessions
        !this.isNew &&
        session.repeat &&
        session.sessiongroup &&
        isDiff(session, this.sessionDetails, [
          'client',
          'rate',
          'start_date_time',
          'location',
          'notes',
          'frequency',
          'end_count'
        ])
      ) {
        const sessiongroup = {
          sessiongroup: session.sessiongroup,
          repeat: true,
          repeat_options: {
            active: true,
            frequency: session.frequency,
            end_count: session.end_count,
            cloned_session_date_time: session.start_date_time
          }
        }
        await this.$store.dispatch('sessiongroups/save', sessiongroup)

        // ask for repeat popup? only if the fields aren't frequency/end_count
        if (
          isDiff(session, this.sessionDetails, [
            'client',
            'rate',
            'start_date_time',
            'location',
            'notes'
          ])
        ) {
          askRepeatPopup = true
        }
      }

      // clean up session object prior to commit
      delete session.full_name // remove added search fields
      delete session.frequency // remove sessiongroup fields
      delete session.end_count // remove sessiongroup fields

      // commit
      try {
        // const sessionid = await this.$store.dispatch('sessions/save', session)

        // repeated scheduling handling
        if (!this.sessionDetails.repeat && session.repeat) {
          const sessionid = await this.$store.dispatch('sessions/save', session)
          // creates/schedules all repeating sessions
          const { data } = await apiService.editEndpoint(
            'misc',
            'schedule_repeating_sessions',
            {
              session: sessionid,
              sessiongroup: session.sessiongroup,
              session_index: session.sessiongroup_session_index,
              overwrite: true
            }
          )
          console.log(data)
          await this.$store.dispatch('sessiongroups/fetch')
          await this.$store.dispatch('sessions/fetch')
        } else if (
          !this.isNew &&
          session.repeat &&
          session.sessiongroup &&
          askRepeatPopup
        ) {
          // editing repeating session, popup modal for single edit or all future sessions
          const { value } = await this.popupSingleOrFuture()
          if (value === undefined) {
            return // user canceled single/future
          } else if (value === false) {
            await this.$store.dispatch('sessions/save', session)
            // user clicked all future sessions
            const { data } = await apiService.editEndpoint(
              'misc',
              'schedule_repeating_sessions',
              {
                session: session.session,
                sessiongroup: session.sessiongroup,
                session_index: session.sessiongroup_session_index,
                overwrite: true
              }
            )
            console.log(data)
            await this.$store.dispatch('sessiongroups/fetch')
            await this.$store.dispatch('sessions/fetch')
          } else {
            // user clicked only this session
            await this.$store.dispatch('sessions/save', session)
          }
        } else if (
          !this.isNew &&
          session.repeat &&
          session.sessiongroup &&
          !askRepeatPopup
        ) {
          await this.$store.dispatch('sessions/save', session)
          // editing repeating session, but only changed frequency or end_count
          const { data } = await apiService.editEndpoint(
            'misc',
            'schedule_repeating_sessions',
            {
              session: session.session,
              sessiongroup: session.sessiongroup,
              session_index: session.sessiongroup_session_index,
              overwrite: false
            }
          )
          console.log(data)
          await this.$store.dispatch('sessiongroups/fetch')
          await this.$store.dispatch('sessions/fetch')
        } else {
          // saves regular non-repeating sessions
          await this.$store.dispatch('sessions/save', session)
        }
        this.showModal = false
      } catch (e) {
        this.$notify(notifications.defaultWarning(e))
      }
    }
  }
}
</script>
<style>
.swal2-container .btn-block {
  width: 75% !important;
}
.no-border {
  border: 0 !important;
}
</style>
